export default {
    bind: function (el, binding) {
        var element = el.getElementsByTagName('input')[0]
        if(!element){
          element = el.getElementsByTagName('textarea')[0]
        } 

        element.addEventListener('keyup', () => {
          element.value = element.value.replace(/[^\a-\z\A-\Z0-9\ก-๙ -]/g, '')
        })
        element.addEventListener('paste', () => {
          element.value = element.value.replace(/[^\a-\z\A-\Z0-9\ก-๙ -]/g, '')
        })
      }
}