var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"navbar navbar-vertical fixed-left navbar-expand-md navbar-light",attrs:{"id":"sidenav-main"}},[_c('div',{staticClass:"container-fluid"},[_c('navbar-toggle-button',{nativeOn:{"click":function($event){return _vm.showSidebar($event)}}},[_c('span',{staticClass:"navbar-toggler-icon"})]),_c('router-link',{staticClass:"navbar-brand",attrs:{"to":"/wallet/dashboard"}},[_c('img',{staticClass:"navbar-brand-img",attrs:{"src":_vm.logo,"alt":"logo"}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$sidebar.showSidebar),expression:"$sidebar.showSidebar"}],staticClass:"navbar-collapse collapse show",attrs:{"id":"sidenav-collapse-main"}},[_c('div',{staticClass:"navbar-collapse-header d-md-none"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 collapse-brand"},[_c('router-link',{attrs:{"to":"/wallet/dashboard"}},[_c('img',{attrs:{"src":_vm.logo}})])],1),_c('div',{staticClass:"col-6 collapse-close"},[_c('navbar-toggle-button',{nativeOn:{"click":function($event){return _vm.closeSidebar($event)}}})],1)])]),_c('div',{staticClass:"card p-2 m-0 mb-2 bg-gradient-primary shadow text-center"},[_c('h2',{staticClass:"text-center text-white mb-0"},[_vm._v(_vm._s(_vm.memberCode))]),_c('h6',{staticClass:"text-center text-white mb-0"},[_vm._v(_vm._s(_vm.customerName))]),(_vm.kycStatus)?_c('h6',{class:("text-center " + (_vm.kycStatus != 'approve' ? 'text-red' : 'text-white') + " mb-0 text-uppercase")},[_vm._v(" KYC : "+_vm._s(_vm.kycStatus)+" ")]):_vm._e()]),(_vm.accessRole.payment)?_c('free-trade-tag'):_vm._e(),_c('hr',{staticClass:"my-3"}),_c('h6',{staticClass:"navbar-heading p-0 text-muted"},[_vm._v("Menu")]),_c('ul',{staticClass:"navbar-nav p-2"},[_c('sidebar-item',{attrs:{"link":{
            name: 'Dashboard',
            icon: 'dashboard',
            path: '/wallet/dashboard',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Notification',
            icon: 'notification',
            path: '/wallet/notification',
            badge: _vm.notiCount,
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'My Account',
            icon: 'wallet',
            path: '/wallet/account',
          }}}),(_vm.accessRole.payee)?_c('sidebar-item',{attrs:{"link":{
            name: 'Payee',
            icon: 'payee',
            path: '/wallet/payee',
          }}}):_vm._e(),(_vm.accessRole.payment)?_c('sidebar-item',{attrs:{"link":{
            name: 'Send',
            icon: 'send',
            path: '/wallet/send',
          }}}):_vm._e(),(_vm.accessRole.autofx)?_c('sidebar-item',{attrs:{"link":{
            name: 'FX',
            icon: 'auto-fx',
            path: '/wallet/fx',
          }}}):_vm._e(),(_vm.accessRole.payment)?_c('sidebar-item',{attrs:{"link":{
            name: 'Favorite',
            icon: 'favorite',
            path: '/wallet/favorite',
          }}}):_vm._e(),(_vm.accessRole.payment)?_c('sidebar-item',{attrs:{"link":{
            name: 'Deposit',
            icon: 'deposit',
            path: '/wallet/deposit',
          }}}):_vm._e(),(_vm.accessRole.payment)?_c('sidebar-item',{attrs:{"link":{
            name: 'History',
            icon: 'pending',
            path: '/wallet/history',
          }}}):_vm._e(),(_vm.accessRole.statement)?_c('sidebar-item',{attrs:{"link":{
            name: 'Statement',
            icon: 'statement',
            path: '/wallet/statement',
          }}}):_vm._e(),(_vm.upgradePlus)?_c('sidebar-item',{attrs:{"link":{
            name: 'Get 50 Free',
            icon: 'introducer',
            path: '/wallet/introducer',
          }}}):_vm._e()],1),_c('hr',{staticClass:"my-3"}),_c('h6',{staticClass:"navbar-heading p-0 text-muted"},[_vm._v("My Account")]),_c('ul',{staticClass:"navbar-nav p-2"},[(_vm.accessRole.usermanage)?_c('sidebar-item',{attrs:{"link":{
            name: 'User Management',
            icon: 'user-management',
            path: '/wallet/user-management',
          }}}):_vm._e(),(_vm.accessRole.term)?_c('sidebar-item',{attrs:{"link":{
            name: 'Terms & Conditions',
            icon: 'terms-and-conditions',
            path: '/wallet/terms',
          }}}):_vm._e(),(_vm.upgradePlus)?_c('sidebar-item',{attrs:{"link":{
            name: 'Upgrade your account',
            icon: 'upgrade',
            path: '/wallet/upgrade',
          }}}):_vm._e(),(_vm.accessRole.changepassword)?_c('sidebar-item',{attrs:{"link":{
            name: 'Change password',
            icon: 'change-password',
            path: '/wallet/password',
          }}}):_vm._e(),_c('li',{staticClass:"nav-item",on:{"click":_vm.logout}},[_c('a',{staticClass:"nav-link",attrs:{"href":"javascript:void(0);"}},[_c('svg-icon',{attrs:{"icon-class":"logout"}}),_c('span',{staticClass:"nav-link-text"},[_vm._v("Logout")])],1)])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }