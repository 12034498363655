const getters = {
  name: (state) => state.user.name,
  username: (state) => state.user.username,
  membercode: (state) => state.user.membercode,
  plusuid: (state) => state.user.plusuid,
  token: (state) => state.user.token,
  introducer: (state) => state.user.introducer,
  business: (state) => state.user.business,
  multiaccount: (state) => state.user.multiaccount,
  swift: (state) => state.user.swift,
  agreement: (state) => state.user.agreement,

  token_manage: (state) => state.manage.token,
  loginas: (state) => state.manage.loginas,
  feature: (state) => state.user.feature,
  userrole: (state) => state.user.userrole,
  accessrole: (state) => state.user.accessrole,
  usertype: (state) => state.user.usertype,
  kycverified: (state) => state.user.kycverified,
  showswifthistory: (state) => state.user.showswifthistory,
  allowedtrade: (state) => state.user.allowedtrade,
  reviewstate: (state) => state.user.reviewstate,
};
export default getters;
