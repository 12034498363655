
<template>
  <div v-loading="loading" style="position: relative; height: 120">
    <div class="d-flex justify-content-between">
      <div style="margin: 15px 0">
        <dropdown-currency
          :currencies="currencies"
          :active="formData.from"
          :ignore="formData.to"
          @onChange="changeFrom"
        />

        <div v-if="swapbutton" 
          style="display: inline-block; position: relative; margin: 0 10px; cursor: pointer;"
          @click="swapCurrency"
        >
          <el-image
            style="width: 48px; height: 32px; vertical-align: middle"
            :src="`/img/exchange.png`"
            fit="contain"
          ></el-image>
        </div>

        <div v-if="!swapbutton"
        style="display: inline-block;margin: 0 10px;"
        ></div>

        <dropdown-currency
          :currencies="currencies"
          :active="formData.to"
          :ignore="formData.from"
          @onChange="changeTo"
        />
      </div>
      <div
        :class="(theme == 'dark' ? 'text-white' : '') + ' text-right mt-2'"
        style="font-size: 1rem"
      >
        <div
          :style="theme == 'dark' ? 'color:#fc4a6d;' : 'color:#ff6384;'"
          v-if="plusRate"
        >
          Plus Rate ≈ {{ plusRate }}
        </div>
        <div :style="theme == 'dark' ? 'color:#85ffcd;' : 'color:#34C88C;'" 
        v-if="type == 'wallet'">
          Rate ≈ {{ walletRate }}
        </div>
        <div style="font-size: 0.85rem">
          {{ changeRate }} / {{ changeRatePercent }}%
        </div>
      </div>
    </div>
    <div class="mb-2" v-if="inapp && type == 'wallet' && plusRate">
      <el-button
        type="danger"
        @click="
          $router.push({ path: '/wallet/upgrade?t=' + new Date().getTime() })
        "
      >
        You want to get {{ plusRate }}? Upgrade your account now!
      </el-button>
    </div>
    <div style="height: 200px">
      <canvas id="compare-currency-chart"></canvas>
    </div>
    <div class="mt-2">
      <el-radio-group
        v-model="formData.timeframe"
        size="mini"
        @change="getRate()"
      >
        <el-radio-button label="5M"></el-radio-button>
        <el-radio-button label="30M"></el-radio-button>
        <el-radio-button label="1H"></el-radio-button>
        <el-radio-button label="4H"></el-radio-button>
        <el-radio-button label="1D"></el-radio-button>

        <div
          style="
            line-height: 1;
            vertical-align: middle;
            display: inline-block;
            margin-left: 10px;
          "
        >
          <el-button size="mini" @click="getRate()">Refresh Rate</el-button>
        </div>
      </el-radio-group>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";
import axios from "axios";
import moment from "moment";
import DropdownCurrency from "./components/DropdownCurrency.vue";

export default {
  name: "CompareCurrency",
  components: {
    DropdownCurrency,
  },
  props: {
    type: {
      type: String,
      default: "wallet",
    },
    currencies: {
      type: Array,
      default: () => [
        "GBP",
        "THB",
        "EUR"
      ],
    },
    theme: {
      type: String,
      default: "ligth",
    },
    inapp: {
      type: Boolean,
      default: false
    },
    swapbutton: {
      type: Boolean,
      default: true
    },
    upgrade: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    plusRate() {
      return this.plusData.data.length > 0 && this.upgrade
        ? this.plusData.data[this.plusData.data.length - 1].toFixed(4)
        : "";
    },
    walletRate() {
      return this.walletData.data.length > 0
        ? this.walletData.data[this.walletData.data.length - 1].toFixed(4)
        : "";
    },
  },
  data() {
    return {
      chart: null,
      timeout: null,
      rateUrl: process.env.VUE_APP_RATE_API_URL,
      rateToken: process.env.VUE_APP_RATE_API_TOKEN,
      ratePath: "/v1/rate/chart",
      loading: true,
      formData: {
        timeframe: "1H",
        startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        from: "GBP",
        to: "THB",
        type: this.type,
      },
      chartData: {
        type: "line",
        data: {
          labels: [],
          datasets: [],
        },
        options: {
          defaultColor: "#fff",
          defaultFontColor: "#fff",
          responsive: true,
          lineTension: 1,
          maintainAspectRatio: false,
          legend: {
            display: true,
            labels: {
              fontColor: this.fontColor(),
            },
            position: "bottom",
          },
          scales: {
            yAxes: [
              {
                color: "#fff",
                type: "linear",
                position: "right",
                ticks: {
                  max: 10,
                  min: 5,
                  fontColor: this.fontColor(),
                },
                gridLines: {
                  color: this.lineColor(),
                  zeroLineColor: this.lineColor(),
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  type: "time",
                  autoSkip: true,
                  maxTicksLimit: 6,
                  maxRotation: 0,
                  minRotation: 0,
                  fontColor: this.fontColor(),
                },
                gridLines: {
                  color: this.lineColor(),
                  zeroLineColor: this.lineColor(),
                },
              },
            ],
          },
        },
      },
      walletData: {
        id: "wallet",
        label: "Rate",
        data: [],
        backgroundColor: "rgba(75, 192, 192, 0.3)",
        borderColor: "#2dde92",
        borderWidth: 2,
        borderJoinStyle: "miter",
        hidden: true,
      },

      plusData: {
        id: "plus",
        label: "Plus rate",
        data: [],
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "#ff6384",
        borderJoinStyle: "miter",
        borderWidth: 2,
        hidden: true,
      },
      changeRate: 0,
      changeRatePercent: 0
    };
  },
  methods: {
    fontColor() {
      return this.theme == "dark" ? "rgba(255,255,255,0.8)" : "rgba(0,0,0,0.8)";
    },
    lineColor() {
      return this.theme == "dark" ? "rgba(255,255,255,0.2)" : "rgba(0,0,0,0.2)";
    },
    getRate() {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.loading = true;
        this.chartData.data.datasets = [];
        this.chartData.data.labels = [];
        axios({
          method: "post",
          baseURL: this.rateUrl,
          url: this.ratePath,
          headers: {
            authorization: this.rateToken,
          },
          data: this.formData,
        }).then((response) => {
          this.loading = false;
          const { result, data } = response.data;
          if (result) {
            this.changeRate = data['change']
            this.changeRatePercent = data['changePercent']
            this.setData(data.data);
          }
        });
      }, 400);
    },
    getData(data) {
      let output = [];
      data.forEach((item) => {
        output.push(item.close);
      });
      return output;
    },
    setDate(data) {
      data.forEach((item) => {
        let date = moment(item.datetime).format("DD MMM YY");
        this.chartData.data.labels.push(date);
      });
    },
    setData(data) {
      //console.log(data)
      let length = data[this.type].length;
      if (length > 0) {
       
        this.setDate(data[this.type]);
        this.walletData.data = this.getData(data["wallet"]);
        this.plusData.data = this.getData(data["plus"]);
        
        if (this.formData.timeframe == "1D") {
          this.walletData.radius = 3;
          this.plusData.radius = 3;
        } else {
          this.walletData.radius = 0;
          this.plusData.radius = 0;
        }

        if (this.type == "plus") {
          this.plusData.hidden = false;
          this.chartData.data.datasets.push(this.plusData);
        } else {
          this.walletData.hidden = false;
          this.plusData.hidden = false;
          this.chartData.data.datasets.push(this.walletData);
          this.chartData.data.datasets.push(this.plusData);
        }


        let allData = this.walletData.data.concat(this.plusData.data);
        let min = Math.min(...allData);
        let max = Math.max(...allData);

        this.chartData.options.scales.yAxes[0].ticks.min = min;
        this.chartData.options.scales.yAxes[0].ticks.max = max;

        if (this.walletData.data.length == 0 || this.plusData.data.length == 0) { 
          this.chartData.options.legend.display = false;
        }

        if(!this.upgrade){
          this.plusData.hidden = true;
          this.chartData.options.legend.display = false
        }

        //console.log(this.chartData.options.scales.yAxes[0].ticks)
        //console.log(this.chartData.data.datasets);
      }

      this.chart.update();
    },
    changeFrom(value) {
      this.formData.from = value.toUpperCase();
      this.getRate();
    },
    changeTo(value) {
      this.formData.to = value.toUpperCase();
      this.getRate();
    },
    swapCurrency() {
      let tempForm = this.formData.from;
      let tempTo = this.formData.to;
      this.formData.from = tempTo;
      this.formData.to = tempForm;
      this.getRate();
    },
  },
  mounted() {
    const ctx = document.getElementById("compare-currency-chart");
    this.chart = new Chart(ctx, this.chartData);
    //console.log(this.chart);
    this.getRate();
  },
};
</script>