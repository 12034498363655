import Cookies from 'js-cookie'

const ManageToKen = "Manage-Token"
const ManageLoginAs = "Manage-LoginAs"

const AuthManage = {
  getToken : ()=> {
    return Cookies.get(ManageToKen)
  },

  setToken:(token) =>{
    return Cookies.set(ManageToKen, token)
  },

  removeToken:()=> {
    return Cookies.remove(ManageToKen)
  },
  getLoginAs : ()=> {
    return Cookies.get(ManageLoginAs)
  },

  setLoginAs:(loginAs) =>{
    return Cookies.set(ManageLoginAs, loginAs) === "1"
  },

  removeLoginAs:()=> {
    return Cookies.remove(ManageLoginAs)
  },

}

export default AuthManage