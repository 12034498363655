

import ElementUI, {Notification} from "element-ui";
import BaseNav from "../components/BaseNav";
import SvgIcon from '../components/SvgIcon'// svg component
import VueQRCodeComponent from 'vue-qrcode-component'
import VueSweetalert2 from 'vue-sweetalert2';
import locale from 'element-ui/lib/locale/lang/en' // lang i18n
import InputMask from 'vue-input-mask';

const GlobalComponents = {
  install(app) {
    app.component("base-nav", BaseNav);
    app.component('svg-icon', SvgIcon);
    app.component('qr-code', VueQRCodeComponent)
    app.component('input-mask', InputMask)
    app.use(ElementUI, {locale});
    app.use(VueSweetalert2, {
      allowOutsideClick: false,
      allowEscapeKey : false,
    });

    app.prototype.$notifyloading = (message)=>{
      return Notification({
        iconClass: "el-icon-loading",
        title: 'Loading',
        message: message,
        duration: 0
      });
    }

    app.prototype.$titlePayeeType = (currency)=>{
      const title = {
        "gbp": "GBP Payment in UK",
        "eur": "EUR Payment (SEPA)",
        "thb": "THB Payment (Thailand)",
        "swt": "SWIFT PAYMENT WORLDWIDE",
        "usd": "USD Fed wire (USA only)",
        "localatw": "Local Payment Across the world",
        "swiftatw": "SWIFT Payment Across the world"
      }
    
      return title[currency] || ""
    }

    app.prototype.$swalTimer = (title, message, type, timer = 5 ) => {

      function formatSeconds(secs) {
        function pad(n) {
          return (n < 10 ? "0" + n : n);
        }
      
        var h = Math.floor(secs / 3600);
        var m = Math.floor(secs / 60) - (h * 60);
        var s = Math.floor(secs - h * 3600 - m * 60);
      
        //return pad(h) + ":" + pad(m) + ":" + pad(s);
        return pad(m) + ":" + pad(s);
      }
      

      let timerInterval
      app.swal.fire({
        title: title,
        html:  message + '. Your request will be expired in <b></b> Minute.',
        icon: type,
        timer: timer * 60000,
        timerProgressBar: true,
        allowOutsideClick: false,
        allowEscapeKey : false,
        didOpen: () => {
          app.swal.showLoading()
          const b = app.swal.getHtmlContainer().querySelector('b')
          timerInterval = setInterval(() => {
            b.textContent = formatSeconds(Math.floor(app.swal.getTimerLeft() / 1000))
          }, 1000)
        },
        willClose: () => {
          clearInterval(timerInterval)
        }
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === app.swal.DismissReason.timer) {
          //console.log('I was closed by the timer')
        }
      })

     }

  },
};

export default GlobalComponents;
