import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import user from './user'
import manage from './manage'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    user,
    manage
  },
  getters
})

export default store
