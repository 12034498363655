<template>
  <div class="freetrade-card" @click="dialogTableVisible = true">
    <div class="freetrade-tag">Free trade available {{ freelists.length || "0" }}</div>
    <el-dialog
      :visible.sync="dialogTableVisible"
      append-to-body
      custom-class="freetrade-modal"
    >
    <div class="text-center"><h3 class="text-white">Make more ‘Free of Charge’ earn it easy! </h3>
    <h2 class="text-white">By invite your friend with code '{{ memberCode }}'</h2></div> 
    <el-image
        class="mb-4"
        src="/img/modal/free_of_charge.jpg"
        style="width: 100%;"
        
      ></el-image>
      
    </el-dialog>
  </div>
</template>

<script>
import PaymentApi from "@/api/payment";
export default {
  computed:{
    memberCode(){
     return this.$store.getters.membercode;
    }
  },
  data() {
    return {
      loading: false,
      dialogTableVisible: false,
      freelists: [],
    };
  },
  methods: {
    getFreeTrade() {
      this.loading = true;
      PaymentApi.freetradeList().then(({ result, data }) => {
        this.loading = false;
        if (result) {
          this.freelists = data;
        }
      });
    },
  },
  mounted() {
    this.getFreeTrade();
  },
};
</script>

<style lang="scss">
.freetrade-card {
  .freetrade-tag {
    background: #f56c6c;
    display: inline-block;
    color: #fff;
    position: relative;
    padding: 10px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    margin: 0 30px 0 0;
    text-decoration: none;
    font-size: 12px;
    width: 90%;
    cursor: pointer;
  }

  .freetrade-tag:hover {
    background-color: #fb763c;
  }

  .freetrade-tag:before {
    background: #fff;
    width: 10px;
    height: 10px;
    content: "";
    display: inline-block;
    border-radius: 20px;
    margin: 0 5px 0 0;
  }

  .freetrade-tag:after {
    display: inline-block;
    border: 19px solid;
    border-color: transparent transparent transparent #f56c6c;
    height: 0;
    width: 0;
    position: absolute;
    right: -38px;
    top: 0;
    content: "";
    display: inline-block;
  }

  .freetrade-tag:hover:after {
    border-color: transparent transparent transparent #fb763c;
  }
}
</style>
