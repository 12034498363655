import axios from 'axios'
import store from '@/store'
import router from '@/router'
import { MessageBox, Message, Notification } from 'element-ui'
import { isMobile } from '@/utils/helpers'
import Swal from 'sweetalert2'
import platform from 'platform'; 

const getUA = () => {
  let device = "Unknown";
  const ua = {
      "Generic Linux": /Linux/i,
      "Android": /Android/i,
      "BlackBerry": /BlackBerry/i,
      "Bluebird": /EF500/i,
      "Chrome OS": /CrOS/i,
      "Datalogic": /DL-AXIS/i,
      "Honeywell": /CT50/i,
      "iPad": /iPad/i,
      "iPhone": /iPhone/i,
      "iPod": /iPod/i,
      "macOS": /Macintosh/i,
      "Windows": /IEMobile|Windows/i,
      "Zebra": /TC70|TC55/i,
  }
  Object.keys(ua).map(v => navigator.userAgent.match(ua[v]) && (device = v));
  return device;
}

// create an axios instance
const service = axios.create({
  baseURL: (process.env.VUE_APP_SERVER_BASE_API || "") + process.env.VUE_APP_SERVER_BASE_API_VERSION, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    if (store.getters.token) {
      config.headers['authorization'] = store.getters.token
    }

    if(isMobile()){
      config.headers['device'] = 'mobile'
      config.headers['app_version'] = '1.5.0'
    }

    config.headers['platform'] = getUA()
    config.headers['platformdetail'] = platform.description
    return config
  },
  error => {
    // do something with request error
    //console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(

  (response) => {
 
    const res = response.data
    return res

  },
  (error) => {
    Notification.closeAll()
    //console.log(error) // for debug
    if (error.response && error.response.status === 401) {
      // to re-login
      // MessageBox.alert('You have been logged out because your authentication is no longer valid', 'Confirm logout', {
      //   confirmButtonText: 'OK',
      //   type: 'warning'
      // }).then(async () => {
      //   await store.dispatch('user/resetToken')
      //   location.reload()
      // })

      Swal.fire({
        icon: 'warning',
        title: 'Confirm logout?',
        text: 'You have been logged out because your authentication is no longer valid',
        allowOutsideClick: false,
        allowEscapeKey : false,
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: 'OK',
      }).then(async () => {
        await store.dispatch('user/resetToken')
        router.push({path: '/login'})
      })
      

    }else if (error.response && error.response.status === 403) {
      router.push({path: '/403', query: {message: error.response.data.message}})

    }else{
      Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
      })

    }


    return Promise.reject(error)
  }
)

export default service