import moment from "moment";
import BankApi from "@/api/bankaccount";
import { uuid } from 'vue-uuid'
import { Loading, Message } from 'element-ui';
const Utils = {
  monthList: [
    { "id": "01", "name": "January" },
    { "id": "02", "name": "February" },
    { "id": "03", "name": "March" },
    { "id": "04", "name": "April" },
    { "id": "05", "name": "May" },
    { "id": "06", "name": "June" },
    { "id": "07", "name": "July" },
    { "id": "08", "name": "August" },
    { "id": "09", "name": "September" },
    { "id": "10", "name": "October" },
    { "id": "11", "name": "November" },
    { "id": "12", "name": "December" }
  ],
  sepaCountries: [
    //{name: "Åland Islands", code: "FI"},
    {name: "Andorra", code: "AD"},
    {name: "Austria", code: "AT"},
    {name: "Azores", code: "PT"},
    {name: "Belgium", code: "BE"},
    {name: "Canary Island", code: "ES"},
    {name: "Cyprus", code: "CY"},
    {name: "Estonia", code: "EE"},
    {name: "Finland", code: "FI"},
    {name: "France", code: "FR"},
    {name: "Germany", code: "DE"},
    {name: "Gibraltar", code: "GI"},
    {name: "Greece", code: "GR"},
    {name: "Ireland", code: "IE"},
    {name: "Italy", code: "IT"},
    {name: "Latvia", code: "LV"},
    {name: "Lithuania", code: "LT"},
    {name: "Luxembourg", code: "LU"},
    {name: "Madeira", code: "PT"},
    {name: "Malta", code: "MT"},
    {name: "Monaco", code: "MC"},
    {name: "Netherlands", code: "NL"},
    {name: "Portugal", code: "PT"},
    {name: "San Marino", code: "SM"},
    {name: "Slovakia", code: "SK"},
    {name: "Slovenia", code: "SI"},
    { name: "Spain", code: "ES" }
  ],
  yearList() {
    let years = [];
    let d = new Date();
    let count = 3;
    let start = d.getFullYear() - 3;
    for (let i = 0; i <= count; i++) {
      years.push(String(start + i));
    }

    return years;
  },
  findObject: (arr, value, key = null) => {
    if (arr instanceof Array && key) {
      return arr.find(x => x[key] == value);
    }

    if (arr instanceof Array && !key) {
      return arr.find(x => x.id == value);
    }
  },

  findObjectArray: (arr, value, key) => {
    if (arr instanceof Array && key) {
      let obj = [];
      for (var i = 0; i < arr.length; i += 1) {
        if (arr[i][key] === value) {
          obj.push(arr[i]);
        }
      }
      return obj;
    }
  },
  param2Obj(url) {
    const search = decodeURIComponent(url.split('?')[1]).replace(/\+/g, ' ')
    if (!search) {
      return {}
    }
    const obj = {}
    const searchArr = search.split('&')
    searchArr.forEach(v => {
      const index = v.indexOf('=')
      if (index !== -1) {
        const name = v.substring(0, index)
        const val = v.substring(index + 1, v.length)
        obj[name] = val
      }
    })
    return obj
  },
  obj2query(obj) {
    return Object.keys(obj).reduce(function (str, key, i) {
      var delimiter, val;
      delimiter = (i === 0) ? '?' : '&';
      key = encodeURIComponent(key);
      val = encodeURIComponent(obj[key]);
      return [str, delimiter, key, '=', val].join('');
    }, '');
  },
  getCurrentDate(format = 'YYYY-MM-DD') {
    return moment().format(format);
  },
  dateFormat(date, format = 'YYYY-MM-DD') {
    if(!date) return '';
    return moment(date).format(format);
  },
  startOfMonth(date, format = 'YYYY-MM-DD') {
    if (!date) return '';
    return moment(date).startOf('month').format(format);
  },
  endOfMonth(date, format = 'YYYY-MM-DD') {
    if (!date) return '';
    return moment(date).endOf('month').format(format);
  },
  mapAccount(data){
    const accounts = []

    Object.keys(data).forEach(key => {
      data[key].forEach(item => {
        accounts.push(item)
      })
    })

    return accounts
  },
  async getBankAccount(currencyOnly = false) {
    // const loading = Loading.service({
    //   customClass: 'el-loading-spinner fullscreen-loading',
    // });
    const { result, data, message } = await BankApi.getMultiAccount()
    //loading.close()
    if (!result) {
      Message.error(message)
      return []
    }

    if (currencyOnly) {
      return Object.keys(data)
    }

    return this.mapAccount(data)

  },

  getBankAccountDetail(bankholderid, bankAccount, mapData = false, html = false) {
    return new Promise(async (resolve, reject) => {
      let bankAccountData = []
      if(!bankAccount){
        bankAccountData = await this.getBankAccount()
      }else{
        if(mapData){
          bankAccountData = this.mapAccount(bankAccount)
        }else{
          bankAccountData = bankAccount
        }
      }
    
      let data = this.findObject(bankAccountData, bankholderid, 'cardholderid')
      let message = ''
      if(data){
        if(html){

        }else{

        }
        if(data.currency === 'GBP'){
          message  = `Account Number: <b>${data.accountnumber}</b> Sortcode: <b>${data.sortcode}</b><br>`
          message  += `IBAN: <b>${data.international.IBAN}</b> SWIFT: <b>${data.international.BIC}</b><br> Correspondent Bank SWIFT: <b>${data.international.correspondent}</b>`
        }else if(data.currency === 'EUR'){
          message  = `IBAN: <b>${data.accountnumber}</b> BIC: <b>${data.sortcode}</b><br>`
          message  += `International<br>`
          message  += `IBAN: <b>${data.international.IBAN}</b> BIC: <b>${data.international.BIC}</b><br> Correspondent Bank SWIFT: <b>${data.international.correspondent}</b>`
        }else{
          message  += `International<br>`
          message  += `IBAN: <b>${data.international.IBAN}</b> BIC: <b>${data.international.BIC}</b><br>`
          message  += `Correspondent Bank SWIFT: <b>${data.international.correspondent}</b> `
        }

        resolve(message)
      }

      resolve('')
    })

  },
  uuid() {
    return uuid.v1()
  },
  numberFormat(number, digi = 2) {
    if(!number) return "";
    if(typeof number == 'string'){
      number = parseFloat(number)
    };
    return number.toFixed(digi).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  },
  numberToString(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },

  getSwiftCurrency(){
    return [
            "AUD", 
            "BDT",
            "BRL",
            "CAD",
            "CHF",
            "CNY",
            "CZK",
            "DKK",
            "EGP",
            "EUR", 
            "GBP", 
            "GHS", 
            "HKD", 
            "HRK", 
            "HUF", 
            "IDR", 
            "ILS", 
            "INR", 
            "JPY", 
            "LKR", 
            "MAD", 
            "MXN", 
            "MYR", 
            "NOK", 
            "NPR", 
            "NZD", 
            "PHP", 
            "PLN", 
            "QAR", 
            "RON",
            "SAR",
            "SEK",
            "SGD",
            "THB",
            "TND",
            "TRY",
            "USD",
            "VND",
            "ZAR",
    ]	
  },

   payeeFilter(data) {
     let output = [];
     data.forEach((item) => {
       if (!['pending', 'reject'].includes(item.status)) {
         output.push(item);
       }
     })

     return output;
  },
   
   getFlagEmoji(countryCode) {
     const codePoints = countryCode
       .toUpperCase()
       .split('')
       .map(char => 127397 + char.charCodeAt());
     return String.fromCodePoint(...codePoints);
  },
   
   getRandomString(length) {
     let result = '';
     const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
     const charactersLength = characters.length;
     for (let i = 0; i < length; i++) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
     }
     return result;
  },
   
  getPaymentLogo(scheme) { 
    var output = '';
    switch (scheme) {
      case 'visa':
        output = 'visa';
        break;
      case 'mastercard':
        output = 'mastercard';
        break;
      default:
        output = 'card-nobrand';
        break;
    }

    return output;
      
  }

  
}

export default Utils

