import request from './request'
import {uuid} from 'vue-uuid'

const BankAccountApi = {
  getAccount(){
    return request({
      url: "/v1/bankaccount/currentview",
      method: "get"
    });
  },
  checkMultiAccount(){
    return request({
      url: "/v1/bankaccount/count",
      method: "get"
    });
  },
  getMultiAccount(){
    return request({
      url: "/v1/bankaccount",
      method: "get"
    });
  },
  getAccountBalance(bankholderid){
    return request({
      url: `/v1/bankaccount/balance/${bankholderid}`,
      method: "get"
    });
  },
  setAccountSelect(data){
    data.idempotency = uuid.v1()
    return request({
      url: "/v1/bankaccount/currentview",
      method: "post",
      data: data
    });
  },
  getASPSPSBank(){
    return request({
      url: "/v1/bankaccount/pis/aspsps",
      method: "get"
    });
  },
  getPisPayment(paymentid){
    return request({
      url: `/v1/bankaccount/pis/payment/${paymentid}`,
      method: "get"
    });
  },
  postPosPayment(data){
    data.idempotency = uuid.v1()
    return request({
      url: "/v1/bankaccount/pis/payment",
      method: "post",
      data: data
    });
  }
}

export default BankAccountApi