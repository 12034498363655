<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
  
    <div class="main-content" :data="sidebarBackground">
      
      <div @click="toggleSidebar">
       
        <transition name="fade" mode="out-in">
        <router-view/>
        </transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>

import ContentFooter from "./ContentFooter.vue";

export default {
  name: "ErrorLayout",
  components: {

    ContentFooter,
  },
  	watch: {
      '$route': function(value) {     
         
      }
    },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary,
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    
  },
};
</script>
<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}


.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
