<template>
  <footer class="container-fluid footer px-4">
    <div class="row align-items-center justify-content-lg-between">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <p class="text-xs text-center">
              A&B GENERAL (UK) Limited is regulated and authorised by the
              Financial Conduct Authority under the payment services (API)<br />
              A&B General (UK) Limited is EMD Agent of the (EMI) Payrnet Limited
              and of Payrnet is authorised and regulated by the FCA registration
              number 716949 in UK.<br />
              Registered Office: m228, Trident Business Centre,89 Bickersteth
              Road, London SW17 9SH. Company Registration number: 6928080
            </p>

            <p class="text-xs text-center">
              The card and accounts are issued by PayrNet Ltd and licensed by
              Mastercard International Incorporation.
              <br />
              PayrNet Ltd is authorised by the FCA to conduct electronic money
              service activities under the Electronic Money Regulations 2011
              (Firm Reference Number 900594)
            </p>

            <p class="text-xs text-center">
              The Financial Services Compensation Scheme does not cover
              electronic money products.
              <br />
              No other compensation scheme exists to cover losses from your
              electronic money account.
              <br />
              Your funds will be held in one or more segregated bank accounts
              with a regulated third party credit institution, in accordance
              with the provisions of the Electronic Money Regulations 2011.
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style></style>
