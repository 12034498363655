import request from '../request'

const Account = {
  confirmRegister(uid) {
    return request({
      url: `/v1/account/${uid}/registerconfirm`,
      method: "post",
    });
  },
  getKycState() { 
    return request({
      url: `/v1/walletaccount/kyc/state`,
      method: "get",
    });
  }
};

export default Account