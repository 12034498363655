import request from './request'
import {uuid} from 'vue-uuid'

export function getToken(){
  return request({
    url: "/v1/login",
    method: "get"
  });
}

export function login(data){
  data.idempotency = uuid.v1()
  return request({
    url: "/v1/login",
    method: "post",
    data
  });
}
export function loginWalletAsStaff(token){
  return request({
    url: "/v1/wallet/loginasstaff/"+token,
    method: "post",
  });
}

export function logout(){
  return request({
    url: "/v1/logout",
    method: "post",
    data: {idempotency: uuid.v1()}
  });
}

export function forgotPassword(data){
  data.idempotency = uuid.v1()
  return request({
    url: "/v1/forgot-login",
    method: "post",
    data: data
  });
}

export function resetForgetPassword(token, data){
  data.idempotency = uuid.v1()
  return request({
    url: "/v1/resetpassword/"+token,
    method: "post",
    data: data
  });
}

export function Agreement(data){
  data.idempotency = uuid.v1()
  return request({
    url: "/v1/agreement",
    method: "post",
    data: data
  });
}

export function getUserLists(plusuid){
  return request({
    url: `/v1/user/${plusuid}/lists`,
    method: "get"
  });
}

export function getUser(uid){
  return request({
    url: `/v1/user/${uid}`,
    method: "get"
  });
}

export function putUser(uid, data){
  data.idempotency = uuid.v1()
  return request({
    url: `/v1/user/${uid}`,
    method: "put",
    data: data
  });
}

export function deleteUser(uid){
  return request({
    url: `/v1/user/${uid}`,
    method: "delete"
  });
}

export function postUser(data){
  data.idempotency = uuid.v1()
  return request({
    url: `/v1/user`,
    method: "post",
    data: data
  });
}

export function postResetPin(uid, data){
  data.idempotency = uuid.v1()
  return request({
      url: `/v1/user/${uid}/resetpin`,
      method: 'post',
      data: data
  })
}

export function postDeleteAccount(data){
  return request({
      url: `/v1/request/delete/user`,
      method: 'post',
      data: data
  })
}