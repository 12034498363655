<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar-manage :background-color="sidebarBackground"> </side-bar-manage>

    <div class="main-content" :data="sidebarBackground">
      <div
        class="header header-balance pb-2 pt-2 d-flex align-items-center"
        style="
          min-height: 200px;
          background-image: url(img/theme/curved14.jpg);
          background-size: cover;
          background-position: center top;
        "
      >
        <span class="mask bg-primary opacity-8"></span>

        <div class="container-fluid">
          <div class="row">
            <div class="col-12 text-center text-xl mb-5">
              <p class="text-white line-1 anim-typewriter">
                A&B Plus Online Banking Manage
              </p>
            </div>
          </div>
        </div>
      </div>

      <div @click="toggleSidebar">
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import ContentFooter from "./ContentFooter.vue";
export default {
  name: "DashboardLayout",
  components: {
    ContentFooter,
  },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary,
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
