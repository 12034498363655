<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">

      <side-bar
        :background-color="sidebarBackground"
        ref="plussidebar"
      >
      </side-bar>
  
    <div class="main-content" :data="sidebarBackground">
      
      <div @click="toggleSidebar">
        <Balance ref="balance"/>
        <transition name="fade" mode="out-in">
        <router-view @updateBalance="updateBalance"/>
        </transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>

import ContentFooter from "./ContentFooter.vue";
import Balance from "../components/Balance/Balance";

export default {
  name: "DashboardLayout",
  components: {
    Balance,
    ContentFooter,
  },
  	watch: {
      '$route': function(value) {     
          this.updateBalance()
      }
    },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary,
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    updateBalance(){
      this.$refs.balance.reload()
      this.$refs.plussidebar.getNotifyCount()
    },
  },
};
</script>
<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}


.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
