export function isMobile() {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return true
    } else {
      return false
    }
  }

  export function generatePassword() {
    //get symbol in generate password 12 characters
    const symbols = "!@#$%^&*()_+";
    const randomSymbol = symbols[Math.floor(Math.random() * symbols.length)];
    const randomNumber = Math.floor(Math.random() * 9);
    const randomLetter =
      String.fromCharCode(
        Math.floor(Math.random() * 26) + 65,
        Math.floor(Math.random() * 26) + 97
      ) +
      Math.floor(Math.random() * 10);
    const randomString = Math.random().toString(36).slice(-8);
    const password =
       randomNumber + randomLetter + randomString + randomSymbol;
    return password;
  }