import router from "@/router";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import store from "@/store";
import { isMobile } from "@/utils/helpers";
import Utils from "@/utils";

NProgress.configure({
  showSpinner: false,
}); // NProgress Configuration

const whiteList = [
  "/",
  "/login",
  "/logout",
  "/403",
  "/404",
  "/lost-password",
  "/forgot-login",
  "/forgot-login/:token",
  "/manage-login",
  "/109in",
  "/ref/:member_code",
  "/app/pis/response",
  "/wallet/payment-topup",
  "/know-your-customer",
  "/embedded/chart",
  "/embedded/review",
  "/register-confirm",
  "/loginasstaff/:token",
  "/request/delete/account",
]; // no redirect whitelist
function return403(next) {
  next({
    path: "/403",
  });
  NProgress.done();
}
router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start();

  const hasToken = store.getters.token;
  const hasTokenManage = store.getters.token_manage;
  const accessRole = store.getters.accessrole;
  const userType = store.getters.usertype;
  const kycverified = store.getters.kycverified;
  if (
    hasToken &&
    !["/know-your-customer", "/logout"].includes(to.path) &&
    !kycverified
  ) {
    return next({
      path: "/know-your-customer",
    });
  }

  if (to.name === "logout") {
    return next();
  } else if (to.name === "loginasstaff") {
    return next();
  } else {
    if (hasTokenManage && to.path.indexOf("/manage") != -1) {
      if (to.path === "/manage-login") {
        next({
          path: "/manage/dashboard",
        });
        NProgress.done();
        return;
      } else {
        return next();
      }
    }

    if (hasToken && to.path.indexOf("/manage") == -1) {
      if (["/login"].includes(to.path)) {
        next({
          path: "/",
        });
        NProgress.done();
        return;
      } else {
        // access role

        let bypassPlus = [
          "/dashboard",
          "/403",
          "/pis-service/response",
          "/know-your-customer",
          "/notification", 
          "/request/delete/account",
          "/embedded/chart",
          "/embedded/review"
        ].includes(to.path);

        if (userType == "plus") {
          if ((to.meta && accessRole[to.meta.access]) || bypassPlus) {
            return next();
          } else {
            return return403(next);
          }
        } else if (userType == "walletuser") {
          if (
            to.path.includes("/wallet") ||
            [
              "/403",
              "/wallet/payment-topup",
              "/know-your-customer",
              "/wallet/notification",
              "/request/delete/account",
              "/embedded/chart",
              "/embedded/review"
            ].includes(to.path)
          ) {
            return next();
          } else if (to.path == "/dashboard") {
            return next(`/wallet/dashboard`);
          } else {
            return return403(next);
          }
        } else {
          return return403(next);
        }
      }
    }
  }

  /* has no token*/
  if (isMobile() && ["/pis-service/response"].includes(to.path)) {
    return next(`/app/pis/response${Utils.obj2query(to.query)}`);
  }

  if (whiteList.includes(to.matched[0].path)) {
    // in the free login whitelist, go directly
    return next();
  } else {
    // other pages that do not have permission to access are redirected to the login page.
    if (to.matched[0].path == "/manage") {
      return next(`/manage-login`);
    }
    next(`/login`);
    NProgress.done();
  }
});

router.afterEach(() => {
  // finish progress bar
  NProgress.done();
});
