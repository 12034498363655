<template>
  <nav
    class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light"
    id="sidenav-main"
  >
    <div class="container-fluid">
      <!--Toggler-->
      <navbar-toggle-button @click.native="showSidebar">
        <span class="navbar-toggler-icon"></span>
      </navbar-toggle-button>
      <router-link class="navbar-brand" to="/wallet/dashboard">
        <img :src="logo" class="navbar-brand-img" alt="logo" />
      </router-link>

      <div
        v-show="$sidebar.showSidebar"
        class="navbar-collapse collapse show"
        id="sidenav-collapse-main"
      >
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <router-link to="/wallet/dashboard">
                <img :src="logo" />
              </router-link>
            </div>

            <div class="col-6 collapse-close">
              <navbar-toggle-button
                @click.native="closeSidebar"
              ></navbar-toggle-button>
            </div>
          </div>
        </div>

        <div class="card p-2 m-0 mb-2 bg-gradient-primary shadow text-center">
            <h2 class="text-center text-white mb-0">{{ memberCode }}</h2>
            <h6 class="text-center text-white mb-0">{{ customerName }}</h6>
            <h6
              v-if="kycStatus"
              :class="`text-center ${
                kycStatus != 'approve' ? 'text-red' : 'text-white'
              } mb-0 text-uppercase`"
            >
              KYC : {{ kycStatus }}
            </h6>
          </div>
          <free-trade-tag v-if="accessRole.payment"></free-trade-tag>

          <hr class="my-3" />
        <h6 class="navbar-heading p-0 text-muted">Menu</h6>

        <ul class="navbar-nav p-2">

          <sidebar-item
            :link="{
              name: 'Dashboard',
              icon: 'dashboard',
              path: '/wallet/dashboard',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Notification',
              icon: 'notification',
              path: '/wallet/notification',
              badge: notiCount,
            }"
          />
          <sidebar-item
            :link="{
              name: 'My Account',
              icon: 'wallet',
              path: '/wallet/account',
            }"
          />
          <sidebar-item
            v-if="accessRole.payee"
            :link="{
              name: 'Payee',
              icon: 'payee',
              path: '/wallet/payee',
            }"
          />
          <sidebar-item
            v-if="accessRole.payment"
            :link="{
              name: 'Send',
              icon: 'send',
              path: '/wallet/send',
            }"
          />
          <sidebar-item
            v-if="accessRole.autofx"
            :link="{
              name: 'FX',
              icon: 'auto-fx',
              path: '/wallet/fx',
            }"
          />

          <sidebar-item
            v-if="accessRole.payment"
            :link="{
              name: 'Favorite',
              icon: 'favorite',
              path: '/wallet/favorite',
            }"
          />
          <sidebar-item
            v-if="accessRole.payment"
            :link="{
              name: 'Deposit',
              icon: 'deposit',
              path: '/wallet/deposit',
            }"
          />

          <sidebar-item
            v-if="accessRole.payment"
            :link="{
              name: 'History',
              icon: 'pending',
              path: '/wallet/history',
            }"
          />
          <sidebar-item
            v-if="accessRole.statement"
            :link="{
              name: 'Statement',
              icon: 'statement',
              path: '/wallet/statement',
            }"
          />

          <sidebar-item
            v-if="upgradePlus"
            :link="{
              name: 'Get 50 Free',
              icon: 'introducer',
              path: '/wallet/introducer',
            }"
          />
        </ul>

        <hr class="my-3" />
        <h6 class="navbar-heading p-0 text-muted">My Account</h6>
        
        <ul class="navbar-nav p-2">
          <sidebar-item
            v-if="accessRole.usermanage"
            :link="{
              name: 'User Management',
              icon: 'user-management',
              path: '/wallet/user-management',
            }"
          />
          <sidebar-item
            v-if="accessRole.term"
            :link="{
              name: 'Terms & Conditions',
              icon: 'terms-and-conditions',
              path: '/wallet/terms',
            }"
          />
          <sidebar-item
            v-if="upgradePlus"
            :link="{
              name: 'Upgrade your account',
              icon: 'upgrade',
              path: '/wallet/upgrade',
            }"
          />

          <sidebar-item
          v-if="accessRole.changepassword"
            :link="{
              name: 'Change password',
              icon: 'change-password',
              path: '/wallet/password',
            }"
          />

          <!-- <sidebar-item
          :link="{
            name: 'Change pin',
            icon: 'mobile',
            path: '/wallet/pin',
          }"
        /> -->

          <li class="nav-item" @click="logout">
            <a class="nav-link" href="javascript:void(0);">
              <svg-icon icon-class="logout" />
              <span class="nav-link-text">Logout</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import NavbarToggleButton from "@/components/NavbarToggleButton";
import AccountApi from "@/api/wallet/account";
import NotificationApi from "@/api/notification";
import FreeTradeTag from '@/components/FreeTrade/FreeTradeTag.vue'
export default {
  name: "sidebar",
  components: {
    NavbarToggleButton,
    FreeTradeTag
  },
  props: {
    logo: {
      type: String,
      default: "/img/brand/logo.png",
      description: "Sidebar app logo",
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        "Whether sidebar should autoclose on mobile when clicking an item",
    },
  },
  computed: {
    userType() {
      return this.$store.getters.usertype;
    },
    plusuid() {
      return this.$store.getters.plusuid;
    },
    isBusiness() {
      return this.$store.getters.business;
    },
    memberCode() {
      return this.$store.getters.membercode;
    },
    customerName() {
      return this.$store.getters.name;
    },
    upgradePlus() {
      return this.$store.getters.feature ? this.$store.getters.feature.upgrade : false;
    },
    accessRole() {
      return this.$store.getters.accessrole;
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  data() {
    return {
      kycStatus: "",
      notiCount: "",
    };
  },
  methods: {
    getKyc() {
      AccountApi.getKycState().then(({ result, data }) => {
        if (result) {
          this.kycStatus = data["status"];
        }
      });
    },
    closeSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    showSidebar() {
      this.$sidebar.displaySidebar(true);
    },
    logout() {
      this.$swal
        .fire({
          title: `Are you sure to logout?`,
          icon: `question`,
          allowOutsideClick: false,
          heightAuto: false,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: `Yes, I'm sure`,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$store.dispatch("user/logout").then(() => {
              this.$router.push(`/login`);
            });
          }
        });
    },
    getNotifyCount() {
      NotificationApi.getCount().then(({ result, data }) => {
        if (result) {
          this.notiCount = data.totalCounts;
        }
      });
    },
    socketSetup() {
      if (this.userType == "walletuser") {
        this.$socket.connect();
        this.$socket.emit('join room', this.plusuid);
        this.sockets.subscribe('connection', () => {
          this.$socket.emit('join room', this.plusuid);
        });
        this.sockets.subscribe("notification:" + this.plusuid, (response) => {
          this.$notify({
            title: response.type,
            message: response.message,
          });
          this.getNotifyCount();
        });
      }
    },
  },
  mounted() {
    this.getKyc();
    this.getNotifyCount();
    this.socketSetup();
  },
  beforeUnmount() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>
