import request from './request'
import {uuid} from 'vue-uuid'
import Auth from './auth'
const Payment = {
    paymentGBP(data){
        data.idempotency = uuid.v1()
        return request({
            url: `/v1/payment/GBP`,
            method: "post",
            data: data
        });
    },
    paymentEUR(data){
        data.idempotency = uuid.v1()
        return request({
            url: `/v1/payment/EUR`,
            method: "post",
            data: data
        });
    },
    paymentTHB(data){
        data.idempotency = uuid.v1()
        return request({
            url: `/v1/payment/THB`,
            method: "post",
            data: data
        });
    },
    paymentSameCurrency(data){
        data.idempotency = uuid.v1()
        return request({
            url: `/v1/payment/global/samecurrency`,
            method: "post",
            data: data
        });
    },
    paymentCrossCurrency(data){
        data.idempotency = uuid.v1()
        return request({
            url: `/v1/payment/global/crosscurrency`,
            method: "post",
            data: data
        });
    },
    paymentCrossCurrencyV2(data){
        data.idempotency = uuid.v1()
        return request({
            url: `/v2/payment/global/crosscurrency`,
            method: "post",
            data: data
        });
    },
    fxRate(data){
        data.idempotency = uuid.v1()
        return request({
            url: `/v1/payment/fxrate`,
            method: "post",
            data: data
        });
    },
    paymentReceipt(payment_uid, filename){
        const token = Auth.getToken()
        const baseURL = (process.env.VUE_APP_SERVER_BASE_API || "") + process.env.VUE_APP_SERVER_BASE_API_VERSION
        const link = document.createElement('a')
        link.href = `${baseURL}/v1/payment/pdf/${payment_uid}?access_token=${token}`;
        if(filename){
          link.download = filename
        }
        link.click()
    },
    pendingSwift(data){
        data.idempotency = uuid.v1()
        return request({
            url: `/v1/payment/pending-swift`,
            method: "post",
            data: data
        });
    },
    pendingPaymentDomestic(data){
        data.idempotency = uuid.v1()
        return request({
            url: `/v1/payment/pending-domestic`,
            method: "post",
            data: data
        });
    },
    getPayeeByCurrency(currency, type){
        const typeParam = ['gbp', 'eur'].includes(currency.toLowerCase()) === false ? `swt/${type}` : `${currency.toLowerCase()}`
        return request({
          url: `/v1/payment/payee/${typeParam}`,
          method: "get"
        });
      },
    serviceActive(){
        return request({
            url: `/v1/payment/global/crosscurrency/serviceisactive`,
            method: "get"
        });
    },
    getPaymentDomesticDetail(paymentUID) { 
        return request({
            url: `/v1/payment/pending-domestic/${paymentUID}`,
            method: 'get'
        })
    },
    getPaymentDomesticDocument(paymentUID) { 
        return request({
            url: `/v1/payment/pending-domestic/${paymentUID}/documents`,
            method: 'get'
        })
    },
    uploadPaymentDomesticDocument(paymentUID, data) { 
        data.idempotency = uuid.v1()
        return request({
            url: `/v1/payment/pending-domestic/${paymentUID}/documents`,
            method: 'post',
            data
        })
    },

    freetradeList(){
        return request({
            url : '/v1/payment/freetradelist',
            method: 'get'
        })
    }

}

export default Payment