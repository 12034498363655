import AuthManage from '@/api/auth-manage'
import ManageApi from '@/api/manage'
import { resetRouter } from '@/router'

const state = {
    token: AuthManage.getToken(),
    loginas: AuthManage.getLoginAs()
}

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_LOGINAS: (state, loginas) => {
        state.loginas = loginas
    }
}

const actions = {
    login({ commit }, userInfo) {
        const { username, password, token } = userInfo
        return new Promise((resolve) => {
            ManageApi.login({ username: username, password: password, token: token }).then((res) => {
                if (!res.result) {
                    return resolve(res)
                }
                if (!res.data.token) {
                    return resolve(res);
                } else {
                    commit("SET_TOKEN", res.data.token);
                    commit("SET_LOGINAS", res.data.loginas);
                    AuthManage.setToken(res.data.token)
                    AuthManage.setLoginAs(res.data.loginas)
                    resolve(res);
                }
            })
        })
    },
    // user logout
    logout({ commit }) {
        return new Promise((resolve) => {
            ManageApi.logout().then(res => {
                if (res && res['result']) {
                    commit("SET_TOKEN", '');
                    commit("SET_LOGINAS", '');
                    AuthManage.removeToken();
                    AuthManage.removeLoginAs();
                    resetRouter()
                    resolve()
                }
            })
        }).catch(error => {
            console.log(error)
        })

    },

    resetToken({ commit }) {
        return new Promise(resolve => {
            commit("SET_TOKEN", '');
            AuthManage.removeToken();
            AuthManage.removeLoginAs();
            resetRouter()
            resolve()
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
