var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"navbar navbar-vertical fixed-left navbar-expand-md navbar-light",attrs:{"id":"sidenav-main"}},[_c('div',{staticClass:"container-fluid"},[_c('navbar-toggle-button',{nativeOn:{"click":function($event){return _vm.showSidebar($event)}}},[_c('span',{staticClass:"navbar-toggler-icon"})]),_c('router-link',{staticClass:"navbar-brand",attrs:{"to":"/"}},[_c('img',{staticClass:"navbar-brand-img",attrs:{"src":_vm.logo,"alt":"logo"}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$sidebar.showSidebar),expression:"$sidebar.showSidebar"}],staticClass:"navbar-collapse collapse show",attrs:{"id":"sidenav-collapse-main"}},[_c('div',{staticClass:"navbar-collapse-header d-md-none"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 collapse-brand"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":_vm.logo}})])],1),_c('div',{staticClass:"col-6 collapse-close"},[_c('navbar-toggle-button',{nativeOn:{"click":function($event){return _vm.closeSidebar($event)}}})],1)])]),_c('ul',{staticClass:"navbar-nav"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Dashboard',
          icon: 'dashboard',
          path: '/manage/dashboard',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Api Log',
          icon: 'api',
          path: '/manage/api',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Roles Setting',
          icon: 'customer-setting',
          path: '/manage/setting/roles',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Auto FX Service',
          icon: 'auto-fx',
          path: '/manage/setting/autofx',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Swift Payment Service',
          icon: 'swiftpayment',
          path: '/manage/setting/swiftpayment',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Rate Operate',
          icon: 'standing-order',
          path: '/manage/setting/rate-offtime',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Marketing Fee',
          icon: 'send',
          path: '/manage/setting/marketing',
        }}})],1),_c('hr',{staticClass:"my-3"}),_c('h6',{staticClass:"navbar-heading p-0 text-muted"},[_vm._v("My Account")]),_c('ul',{staticClass:"navbar-nav"},[_c('li',{staticClass:"nav-item",on:{"click":_vm.logout}},[_c('a',{staticClass:"nav-link",attrs:{"href":"javascript:void(0);"}},[_c('svg-icon',{attrs:{"icon-class":"logout"}}),_c('span',{staticClass:"nav-link-text"},[_vm._v("Logout")])],1)])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }