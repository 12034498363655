import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
Vue.use(Router)

import DashboardLayout from "@/layout/DashboardLayout";
import DashboardManageLayout from "@/layout/DashboardManageLayout";
import ErrorLayout from "@/layout/ErrorLayout";
import walletRoutes from './wallet-routing';

const constantRoutes = [
  {
    path: "/login",
    component: () => import("@/views/Login/Login.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("@/views/Login/Logout.vue"),
  },

  {
    path: "/manage-login",
    name: "managelogin",
    component: () => import("@/views/Manage/Login.vue"),
  },
  {
    path: "/loginasstaff/:token",
    name: "loginasstaff",
    component: () => import("@/views/Login/AsStaff.vue"),
  },
  {
    path: "/lost-password",
    component: () => import("@/views/Login/ForgetPassword.vue"),
    hidden: true,
  },
  {
    path: "/forgot-login",
    component: () => import("@/views/Login/ForgotPassword.vue"),
    hidden: true,
  },
  {
    path: "/forgot-login/:token",
    component: () => import("@/views/Login/NewPassword.vue"),
    hidden: true,
  },
  {
    path: "/ref/:member_code",
    component: () => import("@/views/Introducer/reference.vue"),
    hidden: true,
  },
  {
    path: "/embedded/chart",
    component: () => import("@/components/Chart/CompareCurrency.vue"),
  },
  {
    path: "/embedded/review",
    component: () => import("@/components/Review/Embedded.vue"),
  },
  {
    path: "/register-confirm",
    component: () => import("@/views/User/ConfirmRegister.vue"),
  },
  {
    path: "/request/delete/account",
    component: () => import("@/views/User/RequestDelete.vue"),
  },
  {
    path: "/manage",
    component: DashboardManageLayout,
    children: [
      {
        path: "dashboard",
        name: "ManageDashboard2",
        component: () => import("@/views/Manage/Dashboard.vue"),
      },
      {
        path: "ghostconnect/:id",
        name: "GhostConnect",
        component: () => import("@/views/Manage/GhostConnect.vue"),
      },
      {
        path: "api",
        name: "Apilog",
        component: () => import("@/views/Manage/ApiLog.vue"),
      },
      {
        path: "setting/roles",
        name: "SettingRoles",
        component: () => import("@/views/Manage/RoleSetting.vue"),
      },
      {
        path: "setting/autofx",
        name: "SettingAutoFX",
        component: () => import("@/views/Manage/SettingAutoFX.vue"),
      },
      {
        path: "setting/swiftpayment",
        name: "SettingSwiftPayment",
        component: () => import("@/views/Manage/SettingSwiftPayment.vue"),
      },
      {
        path: "setting/rate-offtime",
        name: "SettingRateOfftime",
        component: () => import("@/views/Manage/SettingRateOfftime.vue"),
      },
      {
        path: "setting/marketing",
        name: "SettingMarketing",
        component: () => import("@/views/Manage/SettingMarketing.vue"),
      },
      {
        path: "users/:id/:plusuid",
        name: "SubUser",
        component: () => import("@/views/Manage/SubUsers.vue"),
      },
    ],
  },
  {
    path: "/403",
    component: ErrorLayout,
    children: [
      {
        path: "",
        name: "error403",
        component: () => import("@/views/Errors/page403.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/dashboard",
    component: DashboardLayout,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => {
          const userType = store.getters.usertype;
          if (userType == "wallet") {
            return import("@/views/Wallet/Dashboard.vue");
          } else {
            return import("@/views/Dashboard/Dashboard.vue");
          }
        },
        meta: { access: "dashboard" },
      },
    ],
  },
  {
    path: "/statement",
    component: DashboardLayout,
    children: [
      {
        path: "",
        name: "statement",
        component: () => import("@/views/Statement/Statement.vue"),
        meta: { access: "statement" },
      },
    ],
  },
  {
    path: "/card",
    component: DashboardLayout,
    children: [
      {
        path: "",
        name: "card",
        component: () => import("@/views/Card/Card.vue"),
        meta: { access: "card" },
      },
      {
        path: "issue",
        name: "cardissue",
        component: () => import("@/views/Card/CardIssue.vue"),
        meta: { access: "card" },
      },
    ],
  },
  {
    path: "/introducer",
    component: DashboardLayout,
    children: [
      {
        path: "",
        name: "introducer",
        component: () => import("@/views/Introducer/Introducer.vue"),
        meta: { access: "introducer" },
      },
    ],
  },
  {
    path: "/notification",
    component: DashboardLayout,
    children: [
      {
        path: "",
        name: "notification",
        component: () => import("@/views/Notification/Notification.vue"),
      },
    ],
  },
  {
    path: "/payment",
    component: DashboardLayout,
    children: [
      {
        path: "",
        name: "payment",
        component: () => import("@/views/Payment/Payment.vue"),
        meta: { access: "payment" },
      },
      {
        path: "gbp",
        name: "PaymentGBP",
        component: () => import("@/views/Payment/PaymentGBP.vue"),
        meta: { access: "payment" },
      },
      {
        path: "eur",
        name: "PaymentEUR",
        component: () => import("@/views/Payment/PaymentEUR.vue"),
        meta: { access: "payment" },
      },
      {
        path: "thb",
        name: "PaymentTHB",
        component: () => import("@/views/Payment/PaymentTHB.vue"),
        meta: { access: "payment" },
      },
      {
        path: "swt",
        name: "PaymentSWT",
        component: () => import("@/views/Payment/PaymentSWT.vue"),
        meta: { access: "payment" },
      },
    ],
  },
  {
    path: "/pending-payment-domestic",
    component: DashboardLayout,
    children: [
      {
        path: "",
        name: "PendingPaymentDomestic",
        component: () => import("@/views/Payment/PendingPaymentDomestic.vue"),
        meta: { access: "payment" },
      },
      {
        path: "detail/:paymentuid",
        name: "PaymentDomesticDetail",
        component: () => import("@/views/Payment/PaymentDomesticDetail.vue"),
        meta: { access: "payment" },
      },
    ],
  },
  {
    path: "/pending-swift-payment",
    component: DashboardLayout,
    children: [
      {
        path: "",
        name: "PendingSwift",
        component: () => import("@/views/Payment/PendingSwift.vue"),
        meta: { access: "swiftpending" },
      },
    ],
  },
  {
    path: "/payee",
    component: DashboardLayout,
    children: [
      {
        path: "",
        name: "payee",
        component: () => import("@/views/Payee/Payee.vue"),
        meta: { access: "payee" },
      },
      {
        path: "view/:currency/:type?",
        name: "payeeView",
        component: () => import("@/views/Payee/PayeeView.vue"),
        meta: { access: "payee" },
      },
      {
        path: "add/:currency/:type?",
        name: "payeeAdd",
        component: () => import("@/views/Payee/PayeeAdd.vue"),
        meta: { access: "payee" },
      },
      {
        path: "edit/:currency/:type/:uid",
        name: "payeeEdit",
        component: () => import("@/views/Payee/PayeeEdit.vue"),
        meta: { access: "payee" },
      },
    ],
  },
  {
    path: "/auto-fx",
    component: DashboardLayout,
    children: [
      {
        path: "",
        name: "autoFX",
        component: () => import("@/views/FX/AutoFX.vue"),
        meta: { access: "autofx" },
      },
    ],
  },
  {
    path: "/request-fx",
    component: DashboardLayout,
    children: [
      {
        path: "",
        name: "requestFX",
        component: () => import("@/views/FX/RequestFX.vue"),
        meta: { access: "manualfx" },
      },
    ],
  },
  {
    path: "/standing-order",
    component: DashboardLayout,
    children: [
      {
        path: "",
        name: "standingOrder",
        component: () => import("@/views/StandingOrder/StandingOrder.vue"),
        meta: { access: "standingorder" },
      },
      {
        path: "add",
        name: "standingOrderAdd",
        component: () => import("@/views/StandingOrder/StandingOrderAdd.vue"),
        meta: { access: "standingorder" },
      },
    ],
  },
  {
    path: "/instant-bank-payment",
    component: DashboardLayout,
    children: [
      {
        path: "",
        name: "instantBankTransfer",
        component: () => import("@/views/FX/InstantBankTransfer.vue"),
        meta: { access: "instant" },
      },
      {
        path: "create",
        name: "newInstantBankTransfer",
        component: () => import("@/views/FX/NewInstantBankTransfer.vue"),
        meta: { access: "instant" },
      },
      {
        path: "payment/:uid",
        name: "payInstantBankTransfer",
        component: () => import("@/views/FX/PayInstantBankTransfer.vue"),
        meta: { access: "instant" },
      },
    ],
  },
  {
    path: "/profile",
    component: DashboardLayout,
    children: [
      {
        path: "",
        name: "profile",
        component: () => import("@/views/Profile/Profile.vue"),
        meta: { access: "viewprofile" },
      },
      {
        path: "/change-password",
        name: "changePassword",
        component: () => import("@/views/Profile/ChangePassword.vue"),
        meta: { access: "changepassword" },
      },
    ],
  },
  {
    path: "/user-management",
    component: DashboardLayout,
    children: [
      {
        path: "",
        name: "manageUser",
        component: () => import("@/views/User/Users.vue"),
        meta: { access: "usermanage" },
      },
    ],
  },
  {
    path: "/receive-bank-transfer",
    component: DashboardLayout,
    children: [
      {
        path: "",
        name: "ReceiveBankTransfer",
        component: () => import("@/views/Dashboard/ReceiveBankTransfer.vue"),
        meta: { access: "receive" },
      },
    ],
  },
  {
    path: "/terms-and-conditions",
    component: DashboardLayout,
    children: [
      {
        path: "",
        name: "AccountTermsAndCondition",
        component: () => import('@/views/Terms/TermsConditions.vue'),
        meta: { access: "term" },
      },
 
    ],
  },
  {
    path: "/price-tariff",
    component: DashboardLayout,
    children: [
      {
        path: "",
        name: "PriceTariff",
        component: () => import("@/views/PriceTariff/PriceTariff"),
        meta: { access: "pricetariff" },
      },
    ],
  },
  {
    path: "/device",
    component: DashboardLayout,
    children: [
      {
        path: "/device",
        name: "Device",
        component: () => import("@/views/Device/Device.vue"),
        meta: { access: "devicemanage" },
      },
    ],
  },
  {
    path: "/transfer",
    component: DashboardLayout,
    children: [
      {
        path: "",
        name: "Transfer",
        component: () => import("@/views/PIS/Transfer.vue"),
        meta: { access: "receive" },
      },
    ],
  },
  {
    path: "/pis-service/response",
    component: DashboardLayout,
    children: [
      {
        path: "",
        name: "TransferResponse",
        component: () => import("@/views/PIS/Response.vue"),
      },
    ],
  },
  {
    path: "/app/pis/response",
    component: () => import("@/views/PIS/AppResponse.vue"),
  },

];

//Object.assign(constantRoutes, walletRoutes)

const createRouter = () => new Router({
  linkActiveClass: "active",
  base: process.env.VUE_APP_BASE_URL,
  mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes.concat(walletRoutes)
})

const router = createRouter()
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
