<template>
  <div class="card card-stats card-balance" v-loading="loading">
    <div class="card-body min-vh-3">
      <div class="row justify-content-md-center" v-if="balance">
        <div class="col">
          <!-- <div class="balance-text">Balance</div> -->
          <div :class="`balance-amount currency-color-${currency.toLowerCase()}`">{{ balance.balance || currency }}</div>
            <div class="row">
            <div class="balance-text col-sm-6 col-lg-12 col-xl-6">Available {{ balance.available }}</div>
          <div class="balance-text col-sm-6 col-lg-12 col-xl-6 text-sm-right text-lg-left text-xl-right">BID {{ bankholderid }}</div>
          </div>
          <!-- <div class="balance-amount">{{ formatNumber(item.available) }}</div> -->
          
        </div>
        <div v-if="balance.balance" :class="`balance-currency currency-color-${currency.toLowerCase()}`">{{currency}}</div>
      
        <!-- <div class="col-auto">
          <div class="balance-currency">{{item.currency}}</div>
        </div> -->
      </div>
      <div class="row justify-content-md-center balance-loading" v-else>
        <div class="col">
          <div :class="`balance-amount currency-color-${currency.toLowerCase()}`"><div class="loading"></div></div>
          <div class="row">
            <div class="balance-text col-sm-6 col-lg-12 col-xl-6"><div class="loading"></div></div>
            <div class="balance-text col-sm-6 col-lg-12 col-xl-6 text-sm-right text-lg-left text-xl-right"><div class="loading"></div></div>
          </div>

        </div>
        <div :class="`balance-currency currency-color-${currency.toLowerCase()}`"><div class="loading"></div></div>

      </div>
    </div>
  </div>
</template>

<script>
import BankApi from "@/api/bankaccount";
export default {
  props: {
    currency: String,
    bankholderid: String
  },
  data(){
    return{
      loading: false,
      balance: null
    }
  },
  methods:{
    getBalance(){
      //this.loading = true
      BankApi.getAccountBalance(this.bankholderid).then(({data})=>{
        // this.loading = false
        this.balance = data
      })
    },
  },
  created(){
    this.getBalance()
  }
}
</script>

<style lang="scss">
.balance-loading{
  .loading{
    display: inline-block;
    background-color: #444;
    height: 10px;
    border-radius: 100px;
    margin: 2px 0;
    width:100%;
    min-width: 50px;
    opacity: .1;
    animation: fading 1.5s infinite;
  }

  @keyframes fading {
  0% {
    opacity: .1;
  }
  
  50% {
    opacity: .2;
  }
  
  100% {
    opacity: .1;
  }
}
}
</style>