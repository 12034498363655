<template>
  <nav
    class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light"
    id="sidenav-main"
  >
    <div class="container-fluid">
      <!--Toggler-->
      <navbar-toggle-button @click.native="showSidebar">
        <span class="navbar-toggler-icon"></span>
      </navbar-toggle-button>
      <router-link class="navbar-brand" to="/">
        <img :src="logo" class="navbar-brand-img" alt="logo" />
      </router-link>

      <div
        v-show="$sidebar.showSidebar"
        class="navbar-collapse collapse show"
        id="sidenav-collapse-main"
      >
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <router-link to="/">
                <img :src="logo" />
              </router-link>
            </div>
            <div class="col-6 collapse-close">
              <navbar-toggle-button
                @click.native="closeSidebar"
              ></navbar-toggle-button>
            </div>
          </div>
        </div>

        <free-trade-tag v-if="accessRole.payment"></free-trade-tag>
        <hr class="my-3" />
        <ul class="navbar-nav p-2">
          <!--   <sidebar-item v-if="isMultiAccount"
          :link="{
            name: 'Accounts',
            icon: 'select',
            path: '/accounts',
          }"
        /> -->
          <sidebar-item
            :link="{
              name: 'Dashboard',
              icon: 'dashboard',
              path: '/dashboard',
            }"
          />
          <sidebar-item
            v-if="accessRole.statement"
            :link="{
              name: 'Statement',
              icon: 'statement',
              path: '/statement',
            }"
          />

          <sidebar-item
            v-if="isFeatureCard && accessRole.card"
            :link="{
              name: 'Card',
              icon: 'card',
              path: '/card',
              exact: true,
            }"
          />
          <sidebar-item
            v-if="accessRole.introducer"
            :link="{
              name: 'Invite Friends Get £50',
              icon: 'introducer',
              path: '/introducer',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Notification',
              icon: 'notification',
              path: '/notification',
              badge: notiCount,
            }"
          />
        </ul>

        <hr class="my-3" />
        <h6 class="navbar-heading p-0 text-muted">Payment And Transfer</h6>

        <ul class="navbar-nav p-2">
          <sidebar-item
            v-if="accessRole.payment"
            :link="{
              name: 'Make a Payment',
              icon: 'payment',
              path: '/payment',
            }"
          />
          <sidebar-item
            v-if="(isSwift && accessRole.swiftpending) || showSwiftHistory"
            :link="{
              name: 'Pending Swift Payment',
              icon: 'pending',
              path: '/pending-swift-payment',
            }"
          />
          <sidebar-item
            v-if="accessRole.payment"
            :link="{
              name: 'Pending Payment Domestic',
              icon: 'domestic',
              path: '/pending-payment-domestic',
              badge: ppdCount,
            }"
          />
          <sidebar-item
            v-if="accessRole.payee"
            :link="{
              name: 'Manage My Payees',
              icon: 'payee',
              path: '/payee',
            }"
          />
          <sidebar-item
            v-if="accessRole.autofx"
            :link="{
              name: 'Auto FX',
              icon: 'auto-fx',
              path: '/auto-fx',
            }"
          />
          <sidebar-item
            v-if="accessRole.manualfx"
            :link="{
              name: 'Request FX',
              icon: 'request-fx',
              path: '/request-fx',
              class: 'nav-item-radius bg-gold',
            }"
          />
          <!-- <sidebar-item
            v-if="accessRole.instant"
            :link="{
              name: 'Instant Bank Transfer',
              icon: 'instant-bank-transfer',
              path: '/instant-bank-payment',
            }"
          /> -->
          <sidebar-item
            v-if="accessRole.standingorder"
            :link="{
              name: 'Standing Orders',
              icon: 'standing-order',
              path: '/standing-order',
            }"
          />
          <sidebar-item
            v-if="accessRole.receive"
            :link="{
              name: 'Receive By Bank Transfer',
              icon: 'receive-bank-transfer',
              path: '/receive-bank-transfer',
            }"
          />
        </ul>

        <hr class="my-3" />
        <h6 class="navbar-heading p-0 text-muted">My Account</h6>

        <ul class="navbar-nav p-2">
          <sidebar-item
            v-if="accessRole.viewprofile"
            :link="{
              name: 'View Profile',
              icon: 'profile',
              path: '/profile',
            }"
          />
          <sidebar-item
            v-if="accessRole.usermanage"
            :link="{
              name: 'User Management',
              icon: 'user-management',
              path: '/user-management',
            }"
          />
          <sidebar-item
            v-if="!isMobile && accessRole.devicemanage"
            :link="{
              name: 'Device Manager',
              icon: 'device',
              path: '/device',
            }"
          />
          <sidebar-item
            v-if="accessRole.changepassword"
            :link="{
              name: 'Change Password',
              icon: 'change-password',
              path: '/change-password',
            }"
          />
          <li
            v-if="isMobile && accessRole.updatepin"
            class="nav-item"
            @click="updatePin()"
          >
            <a class="nav-link" href="javascript:void(0);">
              <svg-icon icon-class="mobile" />
              <span class="nav-link-text">Change PIN</span>
            </a>
          </li>
          <sidebar-item
            v-if="accessRole.term"
            :link="{
              name: 'Terms & Conditions',
              icon: 'terms-and-conditions',
              path: '/terms-and-conditions',
            }"
          />

          <sidebar-item
            v-if="accessRole.pricetariff"
            :link="{
              name: 'Price Tariff',
              icon: 'tariff',
              path: '/price-tariff',
            }"
          />

          <li class="nav-item" @click="logout">
            <a class="nav-link" href="javascript:void(0);">
              <svg-icon icon-class="logout" />
              <span class="nav-link-text">Logout</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <PinDialog ref="pincodedialog" :show-close-button="false" />
  </nav>
</template>
<script>
import NavbarToggleButton from "@/components/NavbarToggleButton";
import { isMobile } from "@/utils/helpers";
import PinDialog from "@/components/PinCode/PinDialog";
import PaymentApi from "@/api/payment";
import NotificationApi from "@/api/notification";
import FreeTradeTag from '@/components/FreeTrade/FreeTradeTag.vue'
export default {
  name: "sidebar",
  components: {
    NavbarToggleButton,
    PinDialog,
    FreeTradeTag
  },
  props: {
    logo: {
      type: String,
      default: "/img/brand/logo.png",
      description: "Sidebar app logo",
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        "Whether sidebar should autoclose on mobile when clicking an item",
    },
  },
  data() {
    return {
      ppdCount: "",
      notiCount: "",
    };
  },
  computed: {
    userType() {
      return this.$store.getters.usertype;
    },
    plusuid() {
      return this.$store.getters.plusuid;
    },
    isMultiAccount() {
      return this.$store.getters.multiaccount;
    },
    isBusiness() {
      return this.$store.getters.business;
    },
    isSwift() {
      return this.$store.getters.swift;
    },
    showSwiftHistory() {
      return this.$store.getters.showswifthistory;
    },
    accessRole() {
      return this.$store.getters.accessrole;
    },
    isMobile() {
      return isMobile();
    },

    isFeatureCard() {
      return this.$store.getters.feature.card;
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  methods: {
    closeSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    showSidebar() {
      this.$sidebar.displaySidebar(true);
    },
    logout() {
      this.$swal
        .fire({
          title: `Are you sure to logout?`,
          icon: `question`,
          allowOutsideClick: false,
          heightAuto: false,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: `Yes, I'm sure`,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$store.dispatch("user/logout").then(() => {
              this.$router.push(`/login`);
            });
          }
        });
    },
    updatePin() {
      this.$refs.pincodedialog.show("update-pin");
    },

    getPendingPaymentDomestic() {
      const query = {
        startdate: "",
        enddate: "",
        filterType: "wait",
      };
      PaymentApi.pendingPaymentDomestic(query).then(({ result, data }) => {
        if (result) {
          this.ppdCount = data.list.length;
        }
      });
    },

    getNotifyCount() {
      NotificationApi.getCount().then(({ result, data }) => {
        if (result) {
          this.notiCount = data.totalCounts;
        }
      });
    },
    socketSetup() {
      if (this.userType == "plus") {
        this.$socket.connect();
        this.$socket.emit('join room', this.plusuid);
        this.sockets.subscribe('connection', () => {
          this.$socket.emit('join room', this.plusuid);
        });
        this.sockets.subscribe("notification:" + this.plusuid, (response) => {
          this.$notify({
            title: response.type,
            message: response.message,
          });
          this.getNotifyCount();
          this.$root.$emit('reload-notification', this.plusuid);
        });
      }
    },
  },
  mounted() {
    this.getPendingPaymentDomestic();
    this.getNotifyCount();
    this.socketSetup();
    
  },
  beforeUnmount() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>
