
<template>
  <div class="pin-dialog">
    <el-dialog
      :title="title"
      width="100%"
      :fullscreen="true"
      :visible.sync="pinDialog"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :before-close="handleClose"
      :show-close="showClose"
    >
      <div v-loading="loading" v-loading.fullscreen.lock="loading">
        <div class="pin-dialog-img">
          <img src="/img/brand/logo2.png" alt="logo" />
        </div>

        <PinCode ref="pincodeInput" @pincode="pinChanged" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PinCode from "./PinCode.vue";
import PinApi from "@/api/pin";
export default {
  props: {
    showCloseButton: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    PinCode,
  },
  data() {
    return {
      title: "Please enter your code",
      loading: false,
      pinDialog: false,
      create: false,
      showClose: true,
      verify: false,
      code: "",
      code2: "",
      resetpin : "reset-pin",
      createpin : "create-pin",
      updatepin : "update-pin"
    };
  },
  methods: {
    async pinChanged(pincode) {
      console.log(this.action)
      if (this.create && [this.createpin, this.resetpin].includes(this.action)) {
        if (this.code === "") {
          this.createPincode(pincode);
        } else {
          this.createPincodeConfirm(pincode);
        }
      }else if (this.action === this.updatepin && this.verify) { 
        if (this.code === "") {
          this.createPincode(pincode);
        } else {
          this.createPincodeConfirm(pincode);
        }
      } else {
        await this.checkPincode(pincode);
      }
    },
    async checkPincode(pincode) {
      this.loading = true;
      PinApi.verify({ pincode: pincode }).then(({ result, message }) => {
        this.loading = false;
        this.verify = result;
        if (result) {
          this.$refs.pincodeInput.triggerSuccess();
          console.log(this.action)
          if(this.action == this.updatepin){
            this.hide();
            this.create = true
            this.title = "Please enter to create a new code";
            this.pinDialog = true;
          }else{
            
            this.saveLog(true)
            setTimeout(() => {
              this.$emit("checked", result);
              this.hide();
            }, 500);
          }

        } else {
          this.saveLog(false)
          this.$refs.pincodeInput.triggerMiss(message);
        }
      });
    },

    createPincode(pincode) {
      this.code = pincode;
      this.loading = true;
      setTimeout(() => {
        this.title = "Please confirm your code";
        this.$refs.pincodeInput.resetPincode();
        this.loading = false;
      }, 700);
    },
    createPincodeConfirm(pincode) {
      if (this.code === pincode) {
        
        if(this.action === this.createpin){
          this.createToServer(pincode)
        }else{
          this.updateToServer(pincode)
        }

      } else {
        this.$refs.pincodeInput.triggerMiss();
      }
    },

    createToServer(){
      this.loading = true;
      PinApi.create({ pincode: this.code }).then(({ result, message }) => {
        this.loading = false;
        this.code = ""
        this.create = false;
        if (result) {
          this.$refs.pincodeInput.triggerSuccess();
          setTimeout(() => {
            this.hide();
          }, 1000);
        } else {
          this.$swal("Message", message, "error");
          this.hide();
        }
      });
    },

    updateToServer(){
      this.loading = true;
      PinApi.update({ pincode: this.code }).then(({ result, message }) => {
        this.loading = false;
        this.code = ""
        this.create = false;
        if (result) {
          this.$refs.pincodeInput.triggerSuccess();
          setTimeout(() => {
            this.hide();
          }, 1000);
        } else {
          this.$swal("Message", message, "error");
          this.hide();
        }
      });
    },

    show(action) {

      this.create = false;
      this.action = action;
      PinApi.get(this.plusuid).then(({ result, message }) => {
        let msg = (message != "success" ? message : "")
        this.loading = false;
        if (!result) {
          this.create = true;
          this.title = "Please enter to create a new code";
          this.pinDialog = true;
        }else if (this.action === this.resetpin){
          this.create = true;
          this.title = "Your code was reset, Please create new code.";
          this.pinDialog = true;
        }else if (this.action === this.updatepin){
          this.create = true;
          this.title = "Please enter Current code";
          this.pinDialog = true;
          this.showClose = true;
        } else {
          if (this.action != this.createpin && this.action != this.resetpin && this.action != this.updatepin) {
            this.title = "Please enter your code";
            this.pinDialog = true;
            setTimeout(() => {
            this.$refs.pincodeInput.showMessage(msg);
          }, 500);
          }
        }
        
      });
    },
    hide() {
      this.pinDialog = false;
      
    },
    handleClose() {
      this.hide();
      this.$emit("checked", false);
    },
    saveLog(result) {
      let data = {
        action: this.action,
        result: result,
      };

      PinApi.pinLog(data).then(({result}) => {
        console.log(`Save pin log : ${result}`);
      });
    },
  },
  create() {
    this.showClose = this.showCloseButton
  }
};
</script>

<style lang="scss">
.pin-dialog {
  .el-dialog {
    background: #e5e5e5;
  }

  .pin-dialog-img {
    text-align: center;
    img {
      width: 50%;
    }
  }
}
</style>