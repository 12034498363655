<template>
    <div
    
      class="header header-balance pb-6 pt-4 d-flex align-items-center"
      style="
        min-height: 200px;
        background-image: url(/img/theme/curved14.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
     <!-- <div
      class="header header-balance pb-6 pt-2 d-flex align-items-center"> -->
      <!-- Mask -->
      <span class="mask bg-primary opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid align-items-center">
        <div class="row justify-content-md-center" v-loading="loading">
          <!---->
          <div v-for="item in bankAccount" :key="item.uid" :class="`col-sm-6 col-lg-3 pb-3 header-balance-area d-grid`" @click="showDetail(item)">
            <Balance :bankholderid="item.cardholderid" :currency="item.currency"/>
          </div>
          <!---->
        </div>
      </div>
      <el-dialog title="Bank Account Detail" :visible.sync="dialogBankDetail" :width="isMobile? '95%' : '50%'">
        <div class="d-flex flex-column position-relative" v-if="bankDetail">
            <h6 class="mb-3 text-sm">{{ bankDetail.currency }} {{ bankDetail.cardholderid }}</h6>
            <div class="mb-2" v-if="['GBP', 'EUR'].includes(bankDetail.currency)">
              <div class="mb-2 text-xs">{{ (bankDetail.currency === 'GBP' ? 'UK Domestic' : 'EUR SEPA') }}</div>
              <div class="mb-2 text-xs">{{ labelAccount(bankDetail.currency) }}: <b>{{ bankDetail.accountnumber }}</b> {{ labelSortcode(bankDetail.currency) }}: <b>{{ bankDetail.sortcode }}</b></div>
            </div>
            <div class="mb-2 text-xs">International</div>
            <div class="mb-2 text-xs">IBAN: <b>{{ bankDetail.international.IBAN }}</b> BIC: <b>{{ bankDetail.international.BIC }}</b> Reference: <b>{{ bankDetail.international.reference }}</b></div>
            <div class="text-xs">Correspondent Bank SWIFT: <b>{{ bankDetail.international.correspondent }}</b></div>
            <div class="has-card" v-if="bankDetail.hascard" @click="gotoCardPage()">
              <img :src="'/img/card/plus_personal.png'" alt="logo" :style="isMobile && 'width:70%'"/>
            </div>
            <div class="statement-btn" @click="gotoStatementPage(bankDetail.cardholderid)">
              <el-tooltip class="item" effect="dark" content="Statement" placement="top">
                <svg-icon icon-class="statement" />
              </el-tooltip> 
            </div>
          
        </div>
      </el-dialog>
    </div>
</template>

<script>
import Balance from "./BalanceItem"
import Utils from "@/utils";
import { isMobile } from "@/utils/helpers";
export default {
  components:{
    Balance
  },
  computed:{
    isMobile() {
      return isMobile();
    },
  },
  data(){
    return {
      dialogBankDetail: false,
      loading: false,
      bankAccount: [],
      bankDetail: null
    }
  },
   methods:{
    labelAccount(currency){
      return currency.toUpperCase() === 'GBP' ? 'Acount Number' : 'IBAN'
    },
    labelSortcode(currency){
      return currency.toUpperCase() === 'GBP' ? 'Sort Code' : 'BIC'
    },
    async getBankAccount(){
      this.bankAccount = []
      this.loading = true
      this.bankAccount = await Utils.getBankAccount();
      this.loading = false
    },
     async reload(){
       await this.getBankAccount()
     },
     showDetail(item){
       this.bankDetail = item
       this.dialogBankDetail = true
     },
     gotoCardPage(){
       let currentPath = this.$route.path
        if(currentPath != "/card"){
          this.$router.push('/card');
        }
        this.dialogBankDetail=false;
     },
     gotoStatementPage(bankholderid){
       let currentPath = this.$route.query.bankholderid
        if(currentPath != bankholderid){
          this.$router.push('/statement?bankholderid='+bankholderid);
        }
        this.dialogBankDetail=false;
     }
   },
   async created(){
     await this.getBankAccount()
   }
};
</script>

<style lang="scss">
  .header-balance {
    .has-card{
      position: absolute;
      right: 10px;
      top: 10px;
      width: 100px;
      text-align: right;

      img{
        width: 100%;
        box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.1);
        cursor: pointer;
      }
    }
    .statement-btn{
      position: absolute;
      right: 10px;
      bottom: 10px;
      cursor: pointer;
      text-align: right;
    }
  }
</style>