import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ArgonDashboard from "./plugins/argon-dashboard";
import "./assets/style/theme/index.css";
import "element-ui/lib/theme-chalk/index.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "@/permission"; // permission control
import VueSocketIO from "vue-socket.io";
import SocketIO from "socket.io-client";
import { DataTables } from "vue-data-tables";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import "flag-icons/css/flag-icons.min.css";
import { VueReCaptcha } from "vue-recaptcha-v3";
Vue.config.productionTip = false;

Vue.use(
  new VueSocketIO({
    debug: false,
    connection: SocketIO(process.env.VUE_APP_SERVER_BASE_API, {
      autoConnect: false,
    }), //options object is Optional
    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_",
    },
  })
);

Vue.use(VueTelInput, {
  mode: "international",
  autoFormat: true,
  validCharactersOnly: true,
  autoDefaultCountry: false,
  defaultCountry: "gb",
  dropdownOptions: {
    showFlags: true,
    showDialCodeInList: true,
    showDialCodeInSelection: false,
  },
  inputOptions: {
    showDialCode: true,
    styleClasses: "form-control-alternative",
  },
  preferredCountries: [
    "th",
    "gb",
    "us",
    "de",
    "se",
    ,
    "it",
    "fr",
    "no",
    "ch",
    "jp",
    "au",
    "cn",
    "sg",
  ],
});
Vue.use(DataTables);
Vue.use(ArgonDashboard);
Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA_SITEKEY,
  loaderOptions: { autoHideBadge: true },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
