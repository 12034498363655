<template>
  <nav
    class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light"
    id="sidenav-main"
  >
  
    <div class="container-fluid">
      <!--Toggler-->
      <navbar-toggle-button @click.native="showSidebar">
        <span class="navbar-toggler-icon"></span>
      </navbar-toggle-button>
      <router-link class="navbar-brand" to="/">
        <img :src="logo" class="navbar-brand-img" alt="logo" />
      </router-link>

      <div
        v-show="$sidebar.showSidebar"
        class="navbar-collapse collapse show"
        id="sidenav-collapse-main"
      >
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <router-link to="/">
                <img :src="logo" />
              </router-link>
            </div>
            <div class="col-6 collapse-close">
              <navbar-toggle-button
                @click.native="closeSidebar"
              ></navbar-toggle-button>
            </div>
          </div>
        </div>


        <ul class="navbar-nav">
         
          <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'dashboard',
            path: '/manage/dashboard',
          }"
        />
        <!-- <sidebar-item
          :link="{
            name: 'Customers',
            icon: 'customer-setting',
            path: '/manage/customer',
          }"
        /> -->
        <sidebar-item
          :link="{
            name: 'Api Log',
            icon: 'api',
            path: '/manage/api',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Roles Setting',
            icon: 'customer-setting',
            path: '/manage/setting/roles',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Auto FX Service',
            icon: 'auto-fx',
            path: '/manage/setting/autofx',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Swift Payment Service',
            icon: 'swiftpayment',
            path: '/manage/setting/swiftpayment',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Rate Operate',
            icon: 'standing-order',
            path: '/manage/setting/rate-offtime',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Marketing Fee',
            icon: 'send',
            path: '/manage/setting/marketing',
          }"
        />

        </ul>

        
        <hr class="my-3" />
        <h6 class="navbar-heading p-0 text-muted">My Account</h6>

        <ul class="navbar-nav">


          <li class="nav-item" @click="logout">
            <a
              class="nav-link"
              href="javascript:void(0);"
              
            >
              <svg-icon icon-class="logout" />
              <span class="nav-link-text">Logout</span>
            </a>
          </li>
        </ul>
        

      </div>
    </div>
  </nav>
</template>
<script>
import NavbarToggleButton from "@/components/NavbarToggleButton";
export default {
  name: "sidebar",
  components: {
    NavbarToggleButton,
  },
  props: {
    logo: {
      type: String,
      default: "/img/brand/logo.png",
      description: "Sidebar app logo",
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        "Whether sidebar should autoclose on mobile when clicking an item",
    },
  },
  computed: {
    isMultiAccount(){
      return this.$store.getters.multiaccount
    },
    isBusiness(){
      return this.$store.getters.business
    }
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  methods: {
    closeSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    showSidebar() {
      this.$sidebar.displaySidebar(true);
    },
    logout() {
      this.$store.dispatch("manage/logout").then(()=>{
        this.$router.push(`/manage-login`);
      });
      
    },
    
  },
  beforeUnmount() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>
