import request from './request'
import Utils from '../utils'
const Notification = {

  getList(query){
    return request({
      url: `/v1/notification${Utils.obj2query(query)}`,
      method: "get"
    });
  },
  getCount(){
    return request({
      url: `/v1/notification/count`,
      method: "get"
    });
  },
  sendMarkread(){
    return request({
      url: `/v1/notification/markread`,
      method: "post"
    });
  }
}

export default Notification