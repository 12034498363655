import clickOutside from "../directives/click-ouside.js";
import inputFilter from "../directives/input-filter.js";
import inputFilterWallet from "../directives/input-filter-wallet.js";
import inputFilterTh from "../directives/input-filter-th.js";
import inputFilteNumber from "../directives/input-filter-number.js";
import inputFilteEn from "../directives/input-filter-en.js";

/**
 * You can register global directives here and use them as a plugin in your main Vue instance
 */

const GlobalDirectives = {
  install(Vue) {
    Vue.directive("click-outside", clickOutside);
    Vue.directive('input-filter', inputFilter)
    Vue.directive('input-filter-wallet', inputFilterWallet)
    Vue.directive('input-filter-th', inputFilterTh)
    Vue.directive('input-number-only', inputFilteNumber)
    Vue.directive('input-filter-en', inputFilteEn)
  },
};

export default GlobalDirectives;
