import DashboardWalletLayout from "@/layout/DashboardWalletLayout";
const walletRoutes = [{
  path: "/wallet",
  component: DashboardWalletLayout,
  children: [
    
    {
      path: "dashboard",
      name: "wallet-dashboard",
      component: () => import('@/views/Wallet/Dashboard.vue'),

    }, {
      path: "account",
      name: "wallet-account",
      component: () => import('@/views/Wallet/Account/Account.vue'),
    },
    {
      path: "account/:currency/:bankholderid",
      name: "wallet-account-currency",
      component: () => import('@/views/Wallet/Account/AccountCurrency.vue'),
    },
    {
      path: "send",
      name: "wallet-send",
      component: () => import('@/views/Wallet/Send/Send.vue'),
    },
    {
      path: "payee",
      name: "wallet-payee",
      component: () => import('@/views/Wallet/Payee/Payee.vue'),
    },
    {
      path: "favorite",
      name: "wallet-favorite",
      component: () => import('@/views/Wallet/Favorite/Favorite.vue'),
    }, 
    {
      path: "favorite/:uid/send",
      name: "wallet-favorite-send",
      component: () => import('@/views/Wallet/Favorite/FavoriteSend.vue'),
    }, 
    {
      path: "deposit",
      name: "wallet-deposit",
      component: () => import('@/views/Wallet/Deposit/Deposit.vue'),
    }, {
      path: "fx",
      name: "wallet-fx",
      component: () => import('@/views/Wallet/FX/FX.vue'),
    }, {
      path: "history",
      name: "wallet-history",
      component: () => import('@/views/Wallet/History/History.vue'),
    }, {
      path: "statement",
      name: "wallet-statement",
      component: () => import('@/views/Wallet/Statement/Statement.vue'),
    }, {
      path: "terms",
      name: "wallet-terms-conditions",
      component: () => import('@/views/Terms/TermsConditions.vue'),
    },
    {
      path: "introducer",
      name: "wallet-introducer",
      component: () => import('@/views/Wallet/User/Upgrade.vue'),
    },
    {
      path: "upgrade",
      name: "upgrade-account",
      component: () => import('@/views/Wallet/User/Upgrade.vue'),
    },
    {
      path: "password",
      name: "wallet-change-password",
      component: () => import('@/views/Profile/ChangePassword.vue'),
    },
    {
      path: "notification",
      name: "wallet-notification",
      component: () => import("@/views/Notification/Notification.vue"),
    },
    {
      path: "user-management",
      name: "wallet-manage-user",
      component: () => import("@/views/User/Users.vue"),
    },
  ],
},
  {
    path: '/wallet/payment-topup',
    component: () => import('@/views/Wallet/Deposit/components/TopupWallet.vue'),
    hidden: true
  },
  {
    path: '/know-your-customer',
    component: () => import('@/views/Wallet/User/UserKYC.vue'),
    hidden: true
  },
];

export default walletRoutes;