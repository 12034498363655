import Cookies from 'js-cookie'

const TokenKey = "Client-Token"
const IntroducerKey = "Client-Introducer"
const NameKey = "Client-Name"
const MemberCodeKey = "Client-MemberCode"
const MultiAccountKey = "Client-MultiAccount"
const BusinessKey = "Client-Business"
const SwiftKey = "Client-Swift"
const AgreementKey = "Client-Agreement"
const PlusUidKey = "Client-PlusUid"
const VenerFeature = "Vener-Feature"
const UserRole = "User-Role"
const UserName = "User-Name"
const UserType = "User-Type"
const AccessRole = "Access-Role"
const KycVerified = "Kyc-Verrified"
const ShowSwiftHistory = "Show-SwiftHistory"
const AllowedTrade = "Allowed-Trade"
const ReviewStateKey = "Review-State"

function isJsonString(str) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

const Auth = {
  getToken: () => {
    return Cookies.get(TokenKey);
  },

  setToken: (token) => {
    return Cookies.set(TokenKey, token);
  },

  removeToken: () => {
    return Cookies.remove(TokenKey);
  },

  getIntroducer() {
    return Cookies.get(IntroducerKey) === "true";
  },

  setIntroducer(introducer) {
    return Cookies.set(IntroducerKey, introducer);
  },

  removeIntroducer() {
    return Cookies.remove(IntroducerKey);
  },

  setName(name) {
    return Cookies.set(NameKey, name);
  },
  getName() {
    return Cookies.get(NameKey);
  },
  removeName() {
    return Cookies.remove(NameKey);
  },

  setMemberCode(MemberCode) {
    return Cookies.set(MemberCodeKey, MemberCode);
  },
  getMemberCode() {
    return Cookies.get(MemberCodeKey);
  },
  removeMemberCode() {
    return Cookies.remove(MemberCodeKey);
  },

  setPlusUID(PlusUID) {
    return Cookies.set(PlusUidKey, PlusUID);
  },
  getPlusUID() {
    return Cookies.get(PlusUidKey);
  },
  removePlusUID() {
    return Cookies.remove(PlusUidKey);
  },

  setMultiAccount(MultiAccount) {
    return Cookies.set(MultiAccountKey, MultiAccount);
  },
  getMultiAccount() {
    return Cookies.get(MultiAccountKey) === "true";
  },
  removeMultiAccount() {
    return Cookies.remove(MultiAccountKey);
  },

  setBusiness(Business) {
    return Cookies.set(BusinessKey, Business);
  },
  getBusiness() {
    return Cookies.get(BusinessKey) === "true";
  },
  removeBusiness() {
    return Cookies.remove(BusinessKey);
  },

  setReviewState(ReviewState) {
    return Cookies.set(ReviewStateKey, ReviewState);
  },
  getReviewState() {
    return Cookies.get(ReviewStateKey) === "true";
  },
  removeReviewState() {
    return Cookies.remove(ReviewStateKey);
  },

  setSwift(Swift) {
    return Cookies.set(SwiftKey, Swift);
  },
  getSwift() {
    return Cookies.get(SwiftKey) === "true";
  },
  removeSwift() {
    return Cookies.remove(SwiftKey);
  },

  setAgreement(Agreement) {
    return Cookies.set(AgreementKey, Agreement);
  },
  getAgreement() {
    return Cookies.get(AgreementKey) === "true";
  },
  removeAgreement() {
    return Cookies.remove(AgreementKey);
  },
  setFeature(Feature) {
    return Cookies.set(VenerFeature, Feature);
  },
  getFeature() {
    return isJsonString(Cookies.get(VenerFeature)) ? JSON.parse(Cookies.get(VenerFeature)) : {};
  },
  removeFeature() {
    return Cookies.remove(VenerFeature);
  },
  getUserRole() {
    return Cookies.get(UserRole);
  },
  setUserRole(userrole) {
    return Cookies.set(UserRole, userrole);
  },
  removeUserRole() {
    return Cookies.remove(UserRole);
  },
  getAccessRole() {
    return isJsonString(Cookies.get(AccessRole)) ? JSON.parse(Cookies.get(AccessRole)) : {};
  },
  setAccessRole(accessrole) {
    return Cookies.set(AccessRole, accessrole);
  },
  removeAccessRole() {
    return Cookies.remove(AccessRole);
  },
  getUserName() {
    return Cookies.get(UserName);
  },
  setUserName(username) {
    return Cookies.set(UserName, username);
  },
  removeUserName() {
    return Cookies.remove(UserName);
  },
  getUserType() {
    return Cookies.get(UserType);
  },
  setUserType(usertype) {
    return Cookies.set(UserType, usertype);
  },
  removeUserType() {
    return Cookies.remove(UserType);
  },
  getKycVerified() {
    return Cookies.get(KycVerified) == "true";
  },
  setKycVerified(verrified) {
    return Cookies.set(KycVerified, verrified);
  },
  removeKycVerified() {
    return Cookies.remove(KycVerified);
  },
  getShowSwiftHistory() {
    return Cookies.get(ShowSwiftHistory) == "true";
  },
  setShowSwiftHistory(showSwifthistory) {
    return Cookies.set(ShowSwiftHistory, showSwifthistory);
  },
  removeShowSwiftHistory() {
    return Cookies.remove(ShowSwiftHistory);
  },
  getAllowedTrade() {
    return Cookies.get(AllowedTrade);
  },
  setAllowedTrade(allowedTrade) {
    return Cookies.set(AllowedTrade, allowedTrade);
  },
  removeAllowedTrade() {
    return Cookies.remove(AllowedTrade);
  },
};

export default Auth