import request from '../request'
import Auth from '../auth'
import Utils from '@/utils'
const Wallet = {
  getCurrencyActiveListNoKYC() {
    return request({
      url: "/v1/wallet/currency/active/listnokyc",
      method: "get"
    });
  },

  getWalletList() { 
    return request({
      url: "/v1/wallet/list",
      method: "get"
    });
  },
  getCurrencyActiveList() {
    return request({
      url: "/v1/wallet/currency/active/list",
      method: "get"
    });
  },

  getCurrencyActiveCreate() { 
    return request({
      url: "/v1/wallet/currency/active/create",
      method: "get"
    });
  },

  createWallet(data) {
    return request({
      url: "/v1/wallet",
      method: "post",
      data: data
    });
  },

  getBankList(currency = "") { 
    return request({
      url: `/v1/wallet/bankname/${currency}`,
      method: 'get'
    });
  },

  getStatement(bankholderid, query) {
    return request({
      url: `/v1/wallet/${bankholderid}/statement`,
      method: 'get',
      params: query
    })
  },

  sendStatement(bankholderid, query) {
    return request({
      url: `/v1/wallet/${bankholderid}/statement/export/pdf`,
      method: 'get',
      params: query
    })
  },
  exportStatement(bankholderid, query, filetype="pdf", filename) {
    const baseURL = (process.env.VUE_APP_SERVER_BASE_API || "") + process.env.VUE_APP_SERVER_BASE_API_VERSION
    const link = document.createElement('a')
    query.token = Auth.getToken()
    link.href = `${baseURL}/v1/wallet/${bankholderid}/statement/export/${filetype}${Utils.obj2query(query)}`;
    if (filename) {
      link.download = filename
    }
    link.click()
  },

  upgradeAccount(data) { 
    return request({
      url: "/v1/walletaccount/upgrade-plus",
      method: "post",
      data: data
    })
  },

  checkWalletReady(data) { 
    return request({
      url: "/v1/walletaccount/check-walletready",
      method: "post",
      data: data
    })
  },

  cardList() { 
    return request({
      url: "/v1/wallet/card/list",
      method: 'get'
    })
  },
  cardRemove(ref) { 
    return request({
      url: `/v1/wallet/card/${ref}`,
      method: 'delete'
    })
  }


}

export default Wallet