<template>
  <el-dropdown 
  @command="onSelected"
  >
    <div style="background: #ebeef5; padding: 15px; border-radius: 15px">
      <el-image
        style="width: 48px; height: 32px; vertical-align: middle"
        :src="`/img/currency-flags/${active.toLowerCase()}.png`"
        fit="contain"
      ></el-image>
      <span class="el-dropdown-link">
        <span style="margin-left: 10px; font-size: 1.2rem">{{
          active.toUpperCase()
        }}</span
        ><i class="el-icon-arrow-down el-icon--right"></i>
      </span>
    </div>

    <el-dropdown-menu 
    slot="dropdown"
    >
      <el-dropdown-item
        v-for="currency of currencies"
        :key="currency"
        :command="currency"
      >
        <el-image
          v-if="ignore.toLowerCase() != currency.toLowerCase()"
          style="width: 48px; height: 32px; vertical-align: middle"
          :src="`/img/currency-flags/${currency.toLowerCase()}.png`"
          fit="contain"
        ></el-image>
        <span
          v-if="ignore.toLowerCase() != currency.toLowerCase()"
          style="margin-left: 10px; font-size: 1.2rem"
          >{{ currency.toUpperCase() }}</span
        >
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  name: "DropdownCurrency",
  props: {
    currencies: {
      type: Array,
      default: () => [],
    },
    active: {
      type: String,
      default: "",
    },
    ignore: {
      type: String,
      default: "",
    },
    
  },

  methods: {
    onSelected(currency) {
      this.currencySelected = currency;
      this.$emit('onChange', currency);
    },
  },
};
</script>

<style>
</style>