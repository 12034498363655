var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"navbar navbar-vertical fixed-left navbar-expand-md navbar-light",attrs:{"id":"sidenav-main"}},[_c('div',{staticClass:"container-fluid"},[_c('navbar-toggle-button',{nativeOn:{"click":function($event){return _vm.showSidebar($event)}}},[_c('span',{staticClass:"navbar-toggler-icon"})]),_c('router-link',{staticClass:"navbar-brand",attrs:{"to":"/"}},[_c('img',{staticClass:"navbar-brand-img",attrs:{"src":_vm.logo,"alt":"logo"}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$sidebar.showSidebar),expression:"$sidebar.showSidebar"}],staticClass:"navbar-collapse collapse show",attrs:{"id":"sidenav-collapse-main"}},[_c('div',{staticClass:"navbar-collapse-header d-md-none"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 collapse-brand"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":_vm.logo}})])],1),_c('div',{staticClass:"col-6 collapse-close"},[_c('navbar-toggle-button',{nativeOn:{"click":function($event){return _vm.closeSidebar($event)}}})],1)])]),(_vm.accessRole.payment)?_c('free-trade-tag'):_vm._e(),_c('hr',{staticClass:"my-3"}),_c('ul',{staticClass:"navbar-nav p-2"},[_c('sidebar-item',{attrs:{"link":{
            name: 'Dashboard',
            icon: 'dashboard',
            path: '/dashboard',
          }}}),(_vm.accessRole.statement)?_c('sidebar-item',{attrs:{"link":{
            name: 'Statement',
            icon: 'statement',
            path: '/statement',
          }}}):_vm._e(),(_vm.isFeatureCard && _vm.accessRole.card)?_c('sidebar-item',{attrs:{"link":{
            name: 'Card',
            icon: 'card',
            path: '/card',
            exact: true,
          }}}):_vm._e(),(_vm.accessRole.introducer)?_c('sidebar-item',{attrs:{"link":{
            name: 'Invite Friends Get £50',
            icon: 'introducer',
            path: '/introducer',
          }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
            name: 'Notification',
            icon: 'notification',
            path: '/notification',
            badge: _vm.notiCount,
          }}})],1),_c('hr',{staticClass:"my-3"}),_c('h6',{staticClass:"navbar-heading p-0 text-muted"},[_vm._v("Payment And Transfer")]),_c('ul',{staticClass:"navbar-nav p-2"},[(_vm.accessRole.payment)?_c('sidebar-item',{attrs:{"link":{
            name: 'Make a Payment',
            icon: 'payment',
            path: '/payment',
          }}}):_vm._e(),((_vm.isSwift && _vm.accessRole.swiftpending) || _vm.showSwiftHistory)?_c('sidebar-item',{attrs:{"link":{
            name: 'Pending Swift Payment',
            icon: 'pending',
            path: '/pending-swift-payment',
          }}}):_vm._e(),(_vm.accessRole.payment)?_c('sidebar-item',{attrs:{"link":{
            name: 'Pending Payment Domestic',
            icon: 'domestic',
            path: '/pending-payment-domestic',
            badge: _vm.ppdCount,
          }}}):_vm._e(),(_vm.accessRole.payee)?_c('sidebar-item',{attrs:{"link":{
            name: 'Manage My Payees',
            icon: 'payee',
            path: '/payee',
          }}}):_vm._e(),(_vm.accessRole.autofx)?_c('sidebar-item',{attrs:{"link":{
            name: 'Auto FX',
            icon: 'auto-fx',
            path: '/auto-fx',
          }}}):_vm._e(),(_vm.accessRole.manualfx)?_c('sidebar-item',{attrs:{"link":{
            name: 'Request FX',
            icon: 'request-fx',
            path: '/request-fx',
            class: 'nav-item-radius bg-gold',
          }}}):_vm._e(),(_vm.accessRole.standingorder)?_c('sidebar-item',{attrs:{"link":{
            name: 'Standing Orders',
            icon: 'standing-order',
            path: '/standing-order',
          }}}):_vm._e(),(_vm.accessRole.receive)?_c('sidebar-item',{attrs:{"link":{
            name: 'Receive By Bank Transfer',
            icon: 'receive-bank-transfer',
            path: '/receive-bank-transfer',
          }}}):_vm._e()],1),_c('hr',{staticClass:"my-3"}),_c('h6',{staticClass:"navbar-heading p-0 text-muted"},[_vm._v("My Account")]),_c('ul',{staticClass:"navbar-nav p-2"},[(_vm.accessRole.viewprofile)?_c('sidebar-item',{attrs:{"link":{
            name: 'View Profile',
            icon: 'profile',
            path: '/profile',
          }}}):_vm._e(),(_vm.accessRole.usermanage)?_c('sidebar-item',{attrs:{"link":{
            name: 'User Management',
            icon: 'user-management',
            path: '/user-management',
          }}}):_vm._e(),(!_vm.isMobile && _vm.accessRole.devicemanage)?_c('sidebar-item',{attrs:{"link":{
            name: 'Device Manager',
            icon: 'device',
            path: '/device',
          }}}):_vm._e(),(_vm.accessRole.changepassword)?_c('sidebar-item',{attrs:{"link":{
            name: 'Change Password',
            icon: 'change-password',
            path: '/change-password',
          }}}):_vm._e(),(_vm.isMobile && _vm.accessRole.updatepin)?_c('li',{staticClass:"nav-item",on:{"click":function($event){return _vm.updatePin()}}},[_c('a',{staticClass:"nav-link",attrs:{"href":"javascript:void(0);"}},[_c('svg-icon',{attrs:{"icon-class":"mobile"}}),_c('span',{staticClass:"nav-link-text"},[_vm._v("Change PIN")])],1)]):_vm._e(),(_vm.accessRole.term)?_c('sidebar-item',{attrs:{"link":{
            name: 'Terms & Conditions',
            icon: 'terms-and-conditions',
            path: '/terms-and-conditions',
          }}}):_vm._e(),(_vm.accessRole.pricetariff)?_c('sidebar-item',{attrs:{"link":{
            name: 'Price Tariff',
            icon: 'tariff',
            path: '/price-tariff',
          }}}):_vm._e(),_c('li',{staticClass:"nav-item",on:{"click":_vm.logout}},[_c('a',{staticClass:"nav-link",attrs:{"href":"javascript:void(0);"}},[_c('svg-icon',{attrs:{"icon-class":"logout"}}),_c('span',{staticClass:"nav-link-text"},[_vm._v("Logout")])],1)])],1)],1)],1),_c('PinDialog',{ref:"pincodedialog",attrs:{"show-close-button":false}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }