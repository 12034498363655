import axios from 'axios'
import store from '@/store'
import router from '@/router'
import { MessageBox, Message, Notification } from 'element-ui'
import {uuid} from 'vue-uuid'
// create an axios instance
const request = axios.create({
    baseURL: (process.env.VUE_APP_SERVER_BASE_API || "") + process.env.VUE_APP_SERVER_BASE_API_VERSION, // url = base url + request url
    timeout: 30000 // request timeout
})

// request interceptor
request.interceptors.request.use(
    config => {
        if (store.getters.token_manage) {
            config.headers['authorization_manage'] = store.getters.token_manage
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

request.interceptors.response.use(

    (response) => {

        const res = response.data
        return res

    },
    (error) => {
        Notification.closeAll()
        //console.log(error) // for debug
        if (error.response && error.response.status === 401) {
            // to re-login
            MessageBox.alert('You have been logged out because your authentication manage is no longer valid', 'Confirm logout', {
                confirmButtonText: 'OK',
                type: 'warning'
            }).then(async () => {
                await store.dispatch('manage/resetToken')
                location.reload()
            })

        } else if (error.response && error.response.status === 403) {
            // to re-login
            router.push('/403')

        } else {
            Message({
                message: error.message,
                type: 'error',
                duration: 5 * 1000
            })

        }

        return Promise.reject(error)
    }
)


const Manage = {
  getToken() {
    return request({
      url: "/v1/manage/login",
      method: "get",
    });
  },
  login(data) {
    return request({
      url: "/v1/manage/login",
      method: "post",
      data: data,
    });
  },
  logout() {
    return request({
      url: "/v1/manage/logout",
      method: "post",
      data: {},
    });
  },
  dashboard() {
    return request({
      url: "/v1/manage/dashboard",
      method: "get",
    });
  },
  search(search) {
    return request({
      url: "/v1/manage/customer/search",
      method: "post",
      data: { search: search },
    });
  },
  getAccountStatus(plusuid) {
    return request({
      url: `/v1/manage/customer/${plusuid}/accountstatus`,
      method: "get",
    });
  },
  updateAccountStatus(plusuid,data) {
    return request({
      url: `/v1/manage/customer/${plusuid}/accountstatus`,
      method: "post",
      data: data,
    });
  },
  getUser(id) {
    return request({
      url: `/v1/manage/user/${id}`,
      method: "get",
    });
  },
  getQRCode() {
    return request({
      url: `/v1/manage/auth/qrcode`,
      method: "get",
    });
  },
  updateUser(id, data) {
    return request({
      url: `/v1/manage/user/${id}`,
      method: "post",
      data: data,
    });
  },
  changePassword(id, data) {
    return request({
      url: `/v1/manage/user/changepassword/${id}`,
      method: "post",
      data: data,
    });
  },
  postTokenGhostConnect(id, data) {
    return request({
      url: `/v1/manage/auth/ghostconnect/${id}`,
      method: "post",
      data: data,
    });
  },

  apiSearch(data) {
    return request({
      url: `/v1/manage/api/search`,
      method: "post",
      data: data,
    });
  },
  apiResend(data) {
    return request({
      url: `/v1/manage/api/resend`,
      method: "post",
      data: data,
    });
  },
  resetPasscode(data) {
    return request({
      url: "/v1/manage/customer/resetpin",
      method: "post",
      data: data,
    });
  },
  getRoleSetting() {
    return request({
      url: `/v1/manage/role`,
      method: "get",
    });
  },
  saveRoleSetting(data) {
    return request({
      url: "/v1/manage/role",
      method: "post",
      data: data,
    });
  },
  getSettingAutoFX() {
    return request({
      url: "/v1/manage/setting/autofx",
      method: "get",
    });
  },
  postSettingAutoFX(data) {
    return request({
      url: "/v1/manage/setting/autofx",
      method: "post",
      data: data,
    });
  },
  getSettingSwiftPayment() {
    return request({
      url: "/v1/manage/setting/swiftpayment",
      method: "get",
    });
  },
  postSettingSwiftPayment(data) {
    return request({
      url: "/v1/manage/setting/swiftpayment",
      method: "post",
      data: data,
    });
  },
  getSettingOfftime() {
    return request({
      url: "/v1/manage/setting/rate-offtime",
      method: "get",
    });
  },
  postSettingOfftime(data) {
    return request({
      url: "/v1/manage/setting/rate-offtime",
      method: "post",
      data: data,
    });
  },
  getSettingMarketing() {
    return request({
      url: "/v1/manage/setting/setting-marketing",
      method: "get",
    });
  },
  postSettingMarketing(data) {
    return request({
      url: "/v1/manage/setting/setting-marketing",
      method: "post",
      data: data,
    });
  },
  getSubuserLists(plusuid) {
    return request({
      url: `/v1/manage/subuser/${plusuid}/lists`,
      method: "get",
    });
  },
  postSubuser(data) {
    data.idempotency = uuid.v1();
    return request({
      url: "/v1/manage/subuser",
      method: "post",
      data: data,
    });
  },
  getSubuser(uid) {
    return request({
      url: `/v1/manage/subuser/${uid}`,
      method: "get",
    });
  },
  putSubuser(uid, data) {
    data.idempotency = uuid.v1();
    return request({
      url: `/v1/manage/subuser/${uid}`,
      method: "put",
      data: data,
    });
  },
  deleteSubuser(uid) {
    return request({
      url: `/v1/manage/subuser/${uid}`,
      method: "delete",
    });
  },
  postSubuserResetPin(uid, data) {
    data.idempotency = uuid.v1();
    return request({
      url: `/v1/manage/subuser/${uid}/resetpin`,
      method: "post",
      data: data,
    });
  },
  postSubuserChangePassword(uid, data) {
    data.idempotency = uuid.v1();
    return request({
      url: `/v1/manage/subuser/${uid}/changepassword`,
      method: "post",
      data: data,
    });
  },
};

export default Manage