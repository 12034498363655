import request from './request'
import {uuid} from 'vue-uuid'

const Pin = {
  verify(data){
    data.idempotency = uuid.v1()
    return request({
      url: "/v1/pin/verify",
      method: "post",
      data: data
    });
  },
  create(data){
    data.idempotency = uuid.v1()
    return request({
      url: "/v1/pin",
      method: "post",
      data: data
    });
  },
  update(data){
    data.idempotency = uuid.v1()
    return request({
      url: "/v1/pin",
      method: "put",
      data: data
    });
  },
  get(){
    return request({
      url: `/v1/pin`,
      method: "get"
    });
  },
  pinLog(data) {
    data.idempotency = uuid.v1()
    return request({
        url: `/v1/logs/pin`,
        method: "post",
        data: data
    });
  },
  getResetPin(){
    return request({
      url: '/v1/customer/resetpin',
      method: 'get'
    })
  }
}

export default Pin