import Auth from "@/api/auth";
import { login, logout } from "@/api/user";
import { resetRouter } from "@/router";

const state = {
  token: Auth.getToken(),
  introducer: Auth.getIntroducer(),
  name: Auth.getName(),
  username: Auth.getUserName(),
  plusuid: Auth.getPlusUID(),
  membercode: Auth.getMemberCode(),
  multiaccount: Auth.getMultiAccount(),
  business: Auth.getBusiness(),
  swift: Auth.getSwift(),
  agreement: Auth.getAgreement(),
  feature: Auth.getFeature(),
  userrole: Auth.getUserRole(),
  accessrole: Auth.getAccessRole(),
  usertype: Auth.getUserType(),
  kycverified: Auth.getKycVerified(),
  showswifthistory: Auth.getShowSwiftHistory(),
  allowedtrade: Auth.getAllowedTrade(),
  reviewstate: Auth.getReviewState(),
};

function setSate(commit, data) {
  commit("SET_TOKEN", data.token);
  commit("SET_INTRODUCER", data.introducerState);
  commit("SET_BUSINESS", data.businnessState);
  commit("SET_SWIFT", data.swiftState);
  commit("SET_AGREEMENT", data.syncagreement);

  commit("SET_NAME", data.user.name);
  commit("SET_USERNAME", data.user.username);
  commit("SET_MEMBERCODE", data.user.membercode);
  commit("SET_PLUSUID", data.user.uid);
  commit("SET_FEATURE", data.feature);
  commit("SET_USERROLE", data.user.role);
  commit("SET_ACCESSROLE", data.user.accessRole);
  commit("SET_USERTYPE", data.userType);
  commit("SET_KYCVERIFIED", data.kycVerified);
  commit("SET_SHOWSWIFTHISTORY", data.showSwifthistory);
  commit("SET_ALLOWEDTRADE", data.allowedTrade);
  commit("SET_REVIEWSTATE", data.reviewState);

  Auth.setToken(data.token);
  Auth.setIntroducer(data.introducerState);
  Auth.setBusiness(data.businnessState);
  Auth.setReviewState(data.reviewState);
  Auth.setSwift(data.swiftState);
  Auth.setAgreement(data.syncagreement);

  Auth.setName(data.user.name);
  Auth.setUserName(data.user.username);
  Auth.setMemberCode(data.user.membercode);
  Auth.setPlusUID(data.user.uid);
  Auth.setFeature(data.feature);
  Auth.setUserRole(data.user.role);
  Auth.setAccessRole(data.user.accessRole);
  Auth.setUserType(data.userType);
  Auth.setKycVerified(data.kycVerified);
  Auth.setShowSwiftHistory(data.showSwifthistory);
  Auth.setAllowedTrade(data.allowedTrade);
}
function removeState(commit) {
  commit("SET_TOKEN", "");
  commit("SET_INTRODUCER", false);
  commit("SET_NAME", "");
  commit("SET_USERNAME", "");
  commit("SET_MEMBERCODE", "");
  commit("SET_SWIFT", "");
  commit("SET_AGREEMENT", "");
  commit("SET_PLUSUID", "");
  commit("SET_FEATURE", "");
  commit("SET_USERROLE", "");
  commit("SET_ACCESSROLE", "");
  commit("SET_USERTYPE", "");
  commit("SET_KYCVERIFIED", "");
  commit("SET_SHOWSWIFTHISTORY", "");
  commit("SET_ALLOWEDTRADE", "");
  commit("SET_REVIEWSTATE", "");
  Auth.removeToken();
  Auth.removeIntroducer();
  Auth.removeName();
  Auth.removeUserName();
  Auth.removeMemberCode();
  Auth.removeMultiAccount();
  Auth.removeBusiness();
  Auth.removeSwift();
  Auth.removeAgreement();
  Auth.removePlusUID();
  Auth.removeFeature();
  Auth.removeUserRole();
  Auth.removeAccessRole();
  Auth.removeUserType();
  Auth.removeKycVerified();
  Auth.removeShowSwiftHistory();
  Auth.removeAllowedTrade();
  Auth.removeReviewState();
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_INTRODUCER: (state, introducer) => {
    state.introducer = introducer;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_USERNAME: (state, username) => {
    state.username = username;
  },
  SET_MEMBERCODE: (state, membercode) => {
    state.membercode = membercode;
  },
  SET_MULTIACCOUNT: (state, multiaccount) => {
    state.multiaccount = multiaccount;
  },
  SET_BUSINESS: (state, business) => {
    state.business = business;
  },
  SET_SWIFT: (state, swift) => {
    state.swift = swift;
  },
  SET_AGREEMENT: (state, agreement) => {
    state.agreement = agreement;
  },
  SET_PLUSUID: (state, uid) => {
    state.plusuid = uid;
  },
  SET_FEATURE: (state, feature) => {
    state.feature = feature;
  },
  SET_USERROLE: (state, userrole) => {
    state.userrole = userrole;
  },
  SET_ACCESSROLE: (state, accessrole) => {
    state.accessrole = accessrole;
  },
  SET_USERTYPE: (state, usertype) => {
    state.usertype = usertype;
  },
  SET_KYCVERIFIED: (state, kycverified) => {
    state.kycverified = kycverified;
  },
  SET_SHOWSWIFTHISTORY: (state, showswifthistory) => {
    state.showswifthistory = showswifthistory;
  },
  SET_ALLOWEDTRADE: (state, allowedTrade) => {
    state.allowedtrade = allowedTrade;
  },
  SET_REVIEWSTATE: (state, reviewState) => {
    state.reviewstate = reviewState;
  },
};

const actions = {
  login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      setSate(commit, userInfo);
      resolve();
    });
  },
  loginAuth({ commit }, data) {
    return new Promise((resolve, reject) => {
      setSate(commit, data);
      resolve(data);
    });
  },

  setAgreement({ commit }, data) {
    return new Promise((resolve) => {
      commit("SET_AGREEMENT", data);
      Auth.setAgreement(data);
      resolve();
    });
  },

  setReviewState({ commit }, data) {
    return new Promise((resolve) => {
      commit("SET_REVIEWSTATE", data);
      Auth.setReviewState(data);
      resolve();
    });
  },

  setKycVerified({ commit }, data) {
    return new Promise((resolve) => {
      commit("SET_KYCVERIFIED", data);
      Auth.setKycVerified(data);
      resolve();
    });
  },
  // user logout
  logout({ commit }) {
    return new Promise((resolve) => {
      logout().then((res) => {
        if (res && res["result"]) {
          removeState(commit);
          resetRouter();
          resolve();
        }
      });
    }).catch((error) => {
      reject(error);
    });
  },
  setMultiAccount({ commit }, multiaccount) {
    return new Promise((resolve) => {
      commit("SET_MULTIACCOUNT", multiaccount);
      Auth.setMultiAccount(multiaccount);
      resolve();
    });
  },
  resetToken({ commit }) {
    return new Promise((resolve) => {
      removeState(commit);
      resetRouter();
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
