<template>
  <li :class="`nav-item`">
    
    <router-link
      :to="{ path: link.path}"
      @click.native="linkClick"
      class="nav-link"
      :target="link.target"
      :exact="exact"
    >
      <svg-icon :icon-class="link.icon" />
      <span class="nav-link-text">{{ link.name }}</span>
      <div class="count-wait" v-if="link.badge">{{ link.badge }}</div>
    </router-link>
    
  </li>
</template>
<script>
export default {
  name: "sidebar-item",
  props: {
    exact: {
      type: Boolean,
      default: false
    },
    link: {
      type: Object,
      default: () => {
        return {
          name: "",
          path: "",
          badge : "",
          children: [],
        };
      },
      description:
        "Sidebar link. Can contain name, path, icon and other attributes. See examples for more info",
    },
  },
  inject: {
    autoClose: {
      default: true,
    },
  },
  data() {
    return {
      children: [],
      collapsed: true,
    };
  },
  methods: {
    linkClick() {
      if (
        this.autoClose &&
        this.$sidebar &&
        this.$sidebar.showSidebar === true
      ) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style lang="scss">
.nav-item{
  position: relative;
  .count-wait{
    position: absolute;
    top: 25%;
    right: -10px;
    background: #fb6340;
    line-height: 2.2;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    text-align: center;
    color: #fff;
    font-size: 12px;
  }
}
</style>
