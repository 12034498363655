<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">

      <side-bar-wallet
        :background-color="sidebarBackground"
        ref="walletsidebar"
      >
      </side-bar-wallet>
  
    <div class="main-content" :data="sidebarBackground">
      
      
      <div class="page-wrapper" @click="toggleSidebar">

        <div
        class="header pb-2 pt-2 d-flex align-items-center"
        style="
          min-height: 200px;
          background-image: url(img/theme/curved14.jpg);
          background-size: cover;
          background-position: center top;
        "
      >
        <span class="mask bg-gradient-primary opacity-8"></span>

        <div class="container-fluid align-items-center">
          
          <div class="row justify-content-md-center">
            <div class="col-12 text-center text-xl mb-5">
              <CompareCurrency ref="comparecurrency" theme="dark" :inapp="true" :currencies="curencies" :upgrade="upgradePlus"/>
            </div>
          </div>
        </div>
      </div>
       
        <transition name="fade" mode="out-in">
        <router-view @updateNotify="updateNotify" @changeTo="changeTo" @changeFrom="changeFrom" @reloadRate="reloadRate"/>
        </transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>

import ContentFooter from "./ContentFooter.vue";
import CompareCurrency from '@/components/Chart/CompareCurrency';
import WalletApi from '@/api/wallet/wallet'
export default {
  name: "DashboardWalletLayout",
  components: {
    CompareCurrency,
    ContentFooter,
  },
  	watch: {
      '$route': function(value) {     
      }
    },
  computed:{
    upgradePlus() {
      return this.$store.getters.feature ? this.$store.getters.feature.upgrade : false;
    },
  },
  data() {
    return {
      curencies:[],
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary,
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    getCurrency(){
      this.curencies = [];
      WalletApi.getCurrencyActiveListNoKYC().then(({result, data})=>{
        if(result){
          data.forEach(item => {
            this.curencies.push(item.code)
          });
        }
      })
    },
    changeFrom(currency) { 
      this.$refs.comparecurrency.changeFrom(currency)
    },
    changeTo(currency) { 
      this.$refs.comparecurrency.changeTo(currency)
    },
    reloadRate() { 
      this.$refs.comparecurrency.getRate()
    },
    updateNotify(){
      this.$refs.walletsidebar.getNotifyCount()
    }
  },
  mounted(){
    this.getCurrency();
  }
};
</script>
<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}


.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
